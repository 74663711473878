import "./CardCharts.css";

const Card = ({ className = "", children, ...props }) => (
  <div className={`card ${className}`} {...props}>
    {children}
  </div>
);

const CardHeader = ({ className = "", children, ...props }) => (
  <div className={`card-header ${className}`} {...props}>
    {children}
  </div>
);

const CardTitle = ({ className = "", children, ...props }) => (
  <h2 className={`card-title ${className}`} {...props}>
    {children}
  </h2>
);

const CardInfo = ({ className = "", children, ...props }) => (
  <div className={`card-Info ${className}`} {...props}>
    {children}
  </div>
);
const CardNetwork = ({ className = "", children, ...props }) => (
  <div className={`card-network ${className}`} {...props}>
    {children}
  </div>
);

const CardContent = ({ className = "", children, ...props }) => (
  <div className={`card-content ${className}`} {...props}>
    {children}
  </div>
);

const CardFooter = ({ className = "", children, ...props }) => (
  <div className={`card-footer ${className}`} {...props}>
    {children}
  </div>
);

export { Card, CardHeader, CardTitle, CardInfo, CardNetwork, CardContent, CardFooter };
