import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PieChartCard from "../../../UI/Charts/pie/ChartCardPie";
import ChartCardBar from "../../../UI/Charts/bar/ChartCardBar";

import "../AnalyticsCharts.styles.css";

const AudienceYT = ({ analyticsAudience }) => {
  const { t } = useTranslation();

  const platform = useSelector((state) => state.analytics.platformSelected);
  const analytics = analyticsAudience?.data?.analytics;

  // Colores fijos proporcionados
  const colors = ["#FF0033", "#000000", "#404040", "#808080", "#BFBFBF"];

  // Función para traducir valores del backend
  const translateValue = (dimension, value) => {
    return t(`analytics.yt.dimensions.${dimension}.${value}`, value); // Fallback al valor original si no hay traducción
  };

  // Función para ordenar datos por valor y asignar colores
  const assignColorsByValue = (data, valueKey) => {
    return data
      .slice() // Crear una copia para no mutar el array original
      .sort((a, b) => b[valueKey] - a[valueKey]) // Ordenar de mayor a menor por el valor
      .map((item, index) => ({
        ...item,
        fill: colors[index % colors.length], // Asignar colores en orden
      }));
  };

  // Función para restaurar el orden original de los datos
  const restoreOriginalOrder = (originalData, transformedData) => {
    const originalOrderMap = originalData.reduce((map, item, index) => {
      const normalizedKey = item.ageGroup
        .replace("age", "")
        .replace("-", " - ");
      map[normalizedKey] = index; // Usar la misma normalización que en transformedData
      return map;
    }, {});
    return transformedData.slice().sort((a, b) => {
      return originalOrderMap[a.name] - originalOrderMap[b.name];
    });
  };

  // Extraer y transformar datos de la dimensión "gender"
  const genderData =
    analytics?.find((item) => item.dimension === "gender")?.data || [];
  const transformedGenderData = assignColorsByValue(
    genderData,
    "viewerPercentage"
  ).map((item) => ({
    name: translateValue("gender", item.gender), // Traducir género
    value: item.viewerPercentage,
    fill: item.fill,
  }));

  // Extraer y transformar datos de la dimensión "country"
  const countryData =
    analytics?.find((item) => item.dimension === "country")?.data || [];
  const transformedCountryData = assignColorsByValue(countryData, "views").map(
    (item) => ({
      name: translateValue("country", item.country), // Traducir país
      value: item.views,
      fill: item.fill,
    })
  );

  // Extraer y transformar datos de la dimensión "ageGroup"
  const ageGroupData =
    analytics?.find((item) => item.dimension === "ageGroup")?.data || [];
  const transformedAgeGroupDataWithColors = assignColorsByValue(
    ageGroupData,
    "viewerPercentage"
  ).map((item) => ({
    name: item.ageGroup.replace("age", "").replace("-", " - "), // Formatear rango etario
    value: item.viewerPercentage,
    fill: item.fill,
  }));
  const transformedAgeGroupData = restoreOriginalOrder(
    ageGroupData,
    transformedAgeGroupDataWithColors
  );

  // Extraer datos de la dimensión "deviceType"
  const deviceTypeData =
    analytics?.find((item) => item.dimension === "deviceType")?.data || [];
  const transformedDeviceTypeData = deviceTypeData.map((item) => ({
    device: translateValue("device_type", item.deviceType), // Traducir tipo de dispositivo
    [t("analytics.yt.metrics.views")]: item.views, // Traducir Views
  }));

  // Extraer datos de la dimensión "insightTrafficSourceType"
  const trafficSourceData =
    analytics?.find((item) => item.dimension === "insightTrafficSourceType")
      ?.data || [];
  const transformedTrafficSourceData = trafficSourceData.map((item) => ({
    source: translateValue(
      "insight_traffic_source_type",
      item.insightTrafficSourceType
    ), // Traducir fuente de tráfico
    [t("analytics.yt.metrics.views")]: item.views, // Traducir Views
  }));

  // Configuración para los gráficos de barras
  const barsDeviceType = [
    {
      dataKey: t("analytics.yt.metrics.views"),
      fill: "#FF0033",
      radius: 20,
      barSize: 20,
    },
  ];

  const barsTrafficSource = [
    {
      dataKey: t("analytics.yt.metrics.views"),
      fill: "#000000",
      radius: 20,
      barSize: 20,
    },
  ];
  return (
    <>
      {/* Gráficos de torta */}
      <div className="analyticsChartsContentPie">
        <PieChartCard
          title={t("analytics.yt.dimensions.gender.title")}
          data={transformedGenderData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadiusFactor={0}
          cornerRadius={4}
          tooltipDescription={t("analytics.yt.dimensions.gender.tooltip")}
          network={platform}
          percentage
        />
        <PieChartCard
          title={t("analytics.yt.dimensions.country.title")}
          data={transformedCountryData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadiusFactor={0.6}
          cornerRadius={4}
          tooltipDescription={t("analytics.yt.dimensions.country.tooltip")}
          network={platform}
        />
        <PieChartCard
          title={t("analytics.yt.dimensions.age_group.title")}
          data={transformedAgeGroupData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadiusFactor={0.6}
          cornerRadius={4}
          tooltipDescription={t("analytics.yt.dimensions.age_group.tooltip")}
          network={platform}
          percentage
        />
      </div>
      {/* Gráficos de barras */}
      <div className="analyticsChartsContentBars">
        <ChartCardBar
          title={t("analytics.yt.dimensions.device_type.title")}
          data={transformedDeviceTypeData}
          xAxisKey="device"
          bars={barsDeviceType}
          isHorizontal={false}
          tooltipDescription={t("analytics.yt.dimensions.device_type.tooltip")}
          network={platform}
        />
        <ChartCardBar
          title={t("analytics.yt.dimensions.insight_traffic_source_type.title")}
          data={transformedTrafficSourceData}
          xAxisKey="source"
          bars={barsTrafficSource}
          isHorizontal={false}
          tooltipDescription={t(
            "analytics.yt.dimensions.insight_traffic_source_type.tooltip"
          )}
          network={platform}
        />
      </div>
    </>
  );
};

export default AudienceYT;
