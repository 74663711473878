import { useTranslation } from "react-i18next";
import error404 from "../../../assets/images/undraw_404.png";

import "./Error.css";
import { useNavigate } from "react-router-dom";

const Error = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/");
  };
  return (
    <div className="error">
      <div className="error__container">
        <img src={error404} alt="error" />
        <p className="error__title">
          {type ? t(`not_found.title.${type}`) : t(`not_found.title.page`)}
        </p>
        <p className="error__text">{t(`not_found.text`)}</p>
        <button className="error__button" onClick={() => handleNavigate()}>
          {type ? t("not_found.btn_back_profile") : t(`not_found.btn_back`)}
        </button>
      </div>
    </div>
  );
};

export default Error;
