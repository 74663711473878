import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <svg
        width={90}
        height={90}
        stroke="#4a1ff6"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g className="spinner">
          <circle cx={12} cy={12} r={9.5} fill="none" strokeWidth={2} />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
