import { useDispatch, useSelector } from "react-redux";
import "./StatusConversations.styles.css";
import { useGetConversationByIdQuery } from "../../../redux/api/conversations/conversationsQuery";
import { useState } from "react";
import {
  setSelectedConversation,
  setSelectedConversationChat,
  setSelectedIdConversation,
} from "../../../redux/api/conversations/conversationsSlice";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import advertiserProfileImg from "../../../assets/images/advertiser_default_img.jpg";
import agencyProfileImg from "../../../assets/images/agency_default_img.jpg";
import { useTranslation } from "react-i18next";

const StatusConversations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const conversations = useSelector(
    (state) => state.conversations.conversations
  );

  const [selectedId, setSelectedId] = useState(null);

  const { data: conversationIdData } = useGetConversationByIdQuery(selectedId, {
    skip: !selectedId,
    refetchOnMountOrArgChange: true,
  });

  const handleChat = (id) => {
    const conversation = conversations?.find((conv) => conv.id === id);
    dispatch(setSelectedConversation(conversation));
    setSelectedId(id);
    dispatch(setSelectedIdConversation(id));
  };

  if (conversationIdData) {
    dispatch(setSelectedConversationChat(conversationIdData.messages));
  }

  const renderConversations = (statuses) => {
    const filteredConversations = conversations?.filter((conversation) =>
      statuses.includes(conversation.status)
    );
    if (filteredConversations?.length === 0) {
      return <span className="emptySpan">{t("chat.conversation.none")}</span>;
    }
    return filteredConversations?.map((conversation) => (
      <div
        className="statusConversations__item"
        key={conversation.id}
        onClick={() => handleChat(conversation.id)}
      >
        <img
          src={
            conversation?.creator
              ? conversation?.creator.profile_img_url
              : conversation?.agency
              ? conversation?.agency.profile_img_url
              : conversation?.advertiser?.profile_img_url
          }
          alt={
            conversation?.creator
              ? conversation?.creator.username
              : conversation?.agency
              ? conversation?.agency.username
              : conversation?.advertiser?.username
          }
          width={32}
          height={32}
          onError={(e) => {
            e.target.src = conversation?.creator
              ? creatorProfileImg
              : conversation?.agency
              ? agencyProfileImg
              : advertiserProfileImg;
          }}
        />
        <label>
          {conversation?.creator
            ? conversation?.creator.username
            : conversation?.agency
            ? conversation?.agency.username
            : conversation?.advertiser?.username}
        </label>
      </div>
    ));
  };

  return (
    <div className="statusConversations__container">
      <p className="statusConversations__containerTitle">
        {t("chat.conversation.title")}
      </p>
      <div className="statusConversations__containerStatus">
        <div className="statusConversations__itemStatus">
          <p>{t("chat.conversation.pending")}</p>
          <div className="statusConversations__renderContainer">
            {renderConversations(["INIT_APPROVAL_PENDING"])}
          </div>
        </div>
        <div className="statusConversations__itemStatus">
          <p>{t("chat.conversation.in_progress")}</p>
          <div className="statusConversations__renderContainer">
            {renderConversations(["ACTIVE", "FINISH_APPROVAL_PENDING"])}
          </div>
        </div>
        <div className="statusConversations__itemStatus">
          <p>{t("chat.conversation.finished")}</p>
          <div className="statusConversations__renderContainer">
            {renderConversations([
              "FINISHED",
              "INIT_REJECTED",
              "FINISH_REJECTED",
            ])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatusConversations;
