import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const opportunitiesApi = createApi({
  reducerPath: "opportunitiesQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["opportunities"],
  endpoints: (builder) => ({
    getOpportunity: builder.query({
      query: () => "/opportunity",
      providesTags: ["opportunities"],
    }),
    getOpportunityById: builder.query({
      query: (id) => `/opportunity/${id}`,
      providesTags: (result, error, id) => [{ type: "Opportunities", id }],
    }),
    addOpportunity: builder.mutation({
      query: (data) => {
        return {
          url: "/opportunity",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["opportunities"],
    }),
    updateOpportunity: builder.mutation({
      query: ({ id, data }) => {
        return {
          url: `/opportunity/${id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["opportunities"],
    }),
    deleteOpportunity: builder.mutation({
      query: (data) => {
        return {
          url: `/opportunity/${data}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["opportunities"],
    }),
  }),
});

export const {
  useGetOpportunityQuery,
  useGetOpportunityByIdQuery,
  useAddOpportunityMutation,
  useUpdateOpportunityMutation,
  useDeleteOpportunityMutation,
  middleware,
} = opportunitiesApi;
export default opportunitiesApi.reducer;
