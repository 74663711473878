// Tooltip.jsx
import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./Tooltip.css"; // Importamos los estilos desde el archivo CSS

const Tooltip = ({ children, content, side = "top", sideOffset = 4 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef(null);

  // Función para calcular la posición del tooltip
  const calculatePosition = () => {
    if (!triggerRef.current) return {};

    const rect = triggerRef.current.getBoundingClientRect();
    const offset = sideOffset;

    switch (side) {
      case "top":
        return {
          left: `${rect.left + rect.width / 2}px`,
          top: `${rect.top - offset}px`,
          transform: "translateX(-50%)",
        };
      case "bottom":
        return {
          left: `${rect.left + rect.width / 2}px`,
          top: `${rect.bottom + offset}px`,
          transform: "translateX(-50%)",
        };
      case "left":
        return {
          left: `${rect.left - offset}px`,
          top: `${rect.top + rect.height / 2}px`,
          transform: "translateY(-50%)",
        };
      case "right":
        return {
          left: `${rect.right + offset}px`,
          top: `${rect.top + rect.height / 2}px`,
          transform: "translateY(-50%)",
        };
      default:
        return {};
    }
  };

  return (
    <div
      style={{ position: "relative", display: "inline-block", cursor: "pointer" }}
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {/* Elemento que activa el tooltip */}
      <div ref={triggerRef}>{children}</div>

      {/* Renderizado condicional del tooltip */}
      {isVisible &&
        ReactDOM.createPortal(
          <div className="tooltip" style={calculatePosition()}>
            {content}
          </div>,
          document.body
        )}
    </div>
  );
};

export default Tooltip;
