import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const conversationsApi = createApi({
  reducerPath: "conversationsQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Conversations"],
  endpoints: (builder) => ({
    getConversations: builder.query({
      query: () => "/chat/conversation",
      providesTags: ["Conversations"],
    }),

    getConversationById: builder.query({
      query: (id) => `/chat/conversation/${id}/message`,
      providesTags: (result, error, id) => [{ type: "Conversations", id }],
    }),

    addConversation: builder.mutation({
      query: (data) => ({
        url: "/chat/conversation",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Conversations"],
    }),

    updateConversationById: builder.mutation({
      query: (data) => ({
        url: `/chat/conversation/${data.id}`,
        method: "PATCH",
        body: { status: data.status, message: data.message },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Conversations", id },
      ],
    }),

    updateMessageById: builder.mutation({
      query: (data) => ({
        url: `/chat/message/${data.id}`,
        method: "PATCH",
        body: { type: data.type },
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "Conversations", id },
      ],
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useGetConversationByIdQuery,
  useAddConversationMutation,
  useUpdateConversationByIdMutation,
  useUpdateMessageByIdMutation,
  middleware,
} = conversationsApi;

export default conversationsApi.reducer;
