import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import barChart from "../../../assets/icons/IconBarChart.svg";
import redirect from "../../../assets/icons/IconRedirect.svg";
import noData from "../../../assets/images/undraw-no-data.svg";

import AreaGradientChartFree from "../../UI/Charts/area/ChartGradientAreaFree";

import "../styles/ProfileAnalytics.css";

const ProfileAnalyticsCreator = ({ userType }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /* const userLoggedIn = useSelector((state) => state.login.user); */
  /* const loggedInType = userLoggedIn?.type?.toLowerCase() || ""; */
  const creatorRedux = useSelector((state) => state.userCreator.creator);

  const handleNavigate = () => {
    navigate(`/analytics/${creatorRedux.username}`);
  };

  const data = [
    {
      month: t("analytics.yt.dimensions.month.1"),
      views: 30,
      likes: 10,
    },
    {
      month: t("analytics.yt.dimensions.month.2"),
      views: 100,
      likes: 60,
    },
    {
      month: t("analytics.yt.dimensions.month.3"),
      views: 70,
      likes: 40,
    },
    {
      month: t("analytics.yt.dimensions.month.4"),
      views: 60,
      likes: 100,
    },
    {
      month: t("analytics.yt.dimensions.month.5"),
      views: 110,
      likes: 40,
    },
    {
      month: t("analytics.yt.dimensions.month.6"),
      views: 70,
      likes: 30,
    },
  ];

  const areas = [
    { dataKey: "likes", color: "#1BE1AF" },
    { dataKey: "views", color: "#8884d8" },
  ];

  return (
    <div className="profileReputationContainer">
      <div className="profileReputationTabs">
        <div className="profileReputationActiveTab">
          <img src={barChart} alt="barChart" />
          <p>{t("analytics.title")}</p>
        </div>
      </div>
      <div className="profileAnalyticContent">
        {creatorRedux?.networks?.length > 0 ? (
          <>
            <div style={{ width: "100%", height: 200, display: "flex" }}>
              <AreaGradientChartFree
                data={data}
                xAxisKey="month"
                areas={areas}
              />
            </div>
            <button
              onClick={() => handleNavigate()}
              className="profileNetworkContentButton"
            >
              <p>{t("profile.creator.btn_go_to_analytics")}</p>
              <img src={redirect} alt="redirect" />
            </button>
          </>
        ) : (
          <>
            <div className="profileAnalyticsContentInfo">
              <img src={noData} alt="no-data" />
              <p>{t("analytics.empty_accounts")}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileAnalyticsCreator;
