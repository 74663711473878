import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignInPage from "./app/auth/SignIn/SignInPage";
import SignUpPage from "./app/auth/SignUp/SignUpPage";
import LandingPage from "./app/Landing/LandingPage";
import EmailVerificationPage from "./app/auth/EmailVerificationPage/EmailVerificationPage";
import TermsOfServicePage from "./app/TermsOfService/TermsOfService";
import PrivacyPolicyPage from "./app/PrivacyPolicy/PrivacyPolicyPage";
import IntegrationPage from "./app/auth/SignUp/IntegrationPage/IntegrationPage";
import ProfilePage from "./app/Profile/ProfilePage";
import { useEffect } from "react";
import ConnectionExternalNetPage from "./app/ConnectionExternalNet/ConnectionExternalNetPage";
import OpportunitiesPage from "./app/Opportunities/OpportunitiesPage";
import HowItWorksPage from "./app/HowItWorks/HowItWorksPage";
import AboutUsPage from "./app/AboutUs/AboutUsPage";
import { setNavigate } from "./utils/authNavigator";
import ConversationsPage from "./app/Conversations/ConversationsPage";
import { useGetUserQuery } from "./redux/api/user/userQuery";
import { updateUserLoggedField } from "./redux/api/auth/login/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearSelectedConversation } from "./redux/api/conversations/conversationsSlice";
import Error404 from "./app/Error404/Error404Page";
import AnalyticsPage from "./app/Analytics/AnalyticsPage";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLoggedIn = useSelector((state) => state.login.user) || null;
  const { data: userData, isSuccess: isDataUserSuccess } = useGetUserQuery(
    undefined,
    {
      skip: !userLoggedIn,
    }
  );

  useEffect(() => {
    if (isDataUserSuccess) {
      dispatch(
        updateUserLoggedField({
          field: "username",
          value: userData?.data.user.username,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "email",
          value: userData?.data.user.email,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "type",
          value: userData?.data.user.type,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "id",
          value: userData?.data.user.id,
        })
      );
    }
  }, [isDataUserSuccess, dispatch, userData]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  useEffect(() => {
    if (isDataUserSuccess && location.pathname === "/") {
      navigate(
        `/${userData?.data?.user?.type?.toLowerCase()}/${
          userData?.data.user.username
        }`
      );
    }
  }, [isDataUserSuccess, location.pathname, navigate, userData]);

  useEffect(() => {
    if (location.pathname !== "/conversations") {
      dispatch(clearSelectedConversation());
    }
  }, [location.pathname, dispatch]);

  return (
    <>
      <Routes>
        <Route path="*" element={<Error404 />} />

        {/* Out App */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/how-it-works" element={<HowItWorksPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/auth/signin" element={<SignInPage />} />
        <Route path="/auth/signup" element={<SignUpPage />} />
        <Route
          path="/auth/email-verification"
          element={<EmailVerificationPage />}
        />
        <Route path="/auth/signup/integration" element={<IntegrationPage />} />

        {/* In App */}
        <Route path="/opportunities" element={<OpportunitiesPage />} />
        <Route path="/conversations" element={<ConversationsPage />} />
        <Route path="/analytics/:username" element={<AnalyticsPage />} />

        {/* dinamic routes */}
        <Route path="/:type/:username" element={<ProfilePage />} />
        <Route
          path="/network/external/:connectioncode"
          element={<ConnectionExternalNetPage />}
        />
      </Routes>
    </>
  );
}

export default App;
