import signUpImage from "../../assets/images/sign-up-person-1.svg";
import "../auth/SignUp/SignUpPage.styles.css";
import SignUpIntegration from "../../components/auth/SignUpIntegration/SignUpIntegration";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalErrorNetwork,
  setModalSuccessNetwork,
} from "../../redux/modalSlice";
import { useTranslation } from "react-i18next";
import InformationModal from "../../components/UI/ModalMessages/InformationModal/InformationModal";

import { useEffect, useState } from "react";
import HeaderOut from "../../components/UI/HeaderOut/HeaderOut";
import { useGetDataFromCCQuery } from "../../redux/api/user/userQuery"; // Cambiado de useGetDataFromCCMutation a useGetDataFromCCQuery
import { useLocation, useNavigate } from "react-router-dom";

function ConnectionExternalNetPage() {
  const navigate = useNavigate();
  const modalError = useSelector((state) => state.modal.modalNetwork);
  const modalSuccess = useSelector((state) => state.modal.modalSuccessNetwork);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const urlConnectionCode = useLocation();
  const UrlCC = urlConnectionCode.pathname.split("/")[3];

  const [modalAlreadyConnected, setModalAlreadyConnected] = useState(false);
  const [modalErrorUndefined, setModalErrorUndefined] = useState(false);
  const [modalData, setModalData] = useState({});

  const modalDataSuccess = {
    icon: "check",
    title: t("profile.networks.integration.ok"),
    text: t("profile.networks.integration.success"),
    textButtonClose: t("general.btn_return"),
  };

  const {
    /* data: getDataFromCCData, */
    isError: isGetDataFromCCError,
    error: getDataFromCCError,
  } = useGetDataFromCCQuery(UrlCC);

  useEffect(() => {
    if (isGetDataFromCCError) {
      console.error("error", getDataFromCCError);
      navigate("/");
    }
  }, [isGetDataFromCCError, getDataFromCCError, navigate]);

  const handleClose = () => {
    dispatch(setModalErrorNetwork({ modal: false, message: null }));
    setModalAlreadyConnected(false);
    setModalErrorUndefined(false);
    dispatch(setModalSuccessNetwork(false));
  };

  useEffect(() => {
    if (modalError.modal) {
      if (modalError.message === "Account already connected.") {
        setModalAlreadyConnected(true);
        setModalData({
          icon: "warning",
          title: t("profile.networks.integration.error"),
          text: t("profile.networks.integration.already_integrated"),
          textButtonClose: t("general.btn_return"),
        });
      } else {
        setModalErrorUndefined(true);
        setModalData({
          icon: "warning",
          title: t("profile.networks.integration.error"),
          text: t("profile.networks.integration.error_add_network"),
          textButtonClose: t("general.btn_return"),
        });
      }
    }
  }, [modalError, t]);

  return (
    <>
      <HeaderOut wType={true} />
      <div className="signUpContainer">
        <div className="signUpFormContainer">
          <SignUpIntegration UrlCC={UrlCC} />
        </div>
        <div className="signUpImageContainer">
          <img src={signUpImage} alt="sign-Up" className="signUpImage" />
        </div>
        {modalAlreadyConnected && (
          <InformationModal
            data={modalData}
            onClose={handleClose}
            onAccept={null}
          />
        )}
        {modalErrorUndefined && (
          <InformationModal
            data={modalData}
            onClose={handleClose}
            onAccept={null}
          />
        )}
        {modalSuccess && (
          <InformationModal
            data={modalDataSuccess}
            onClose={handleClose}
            onAccept={null}
          />
        )}
      </div>
    </>
  );
}

export default ConnectionExternalNetPage;
