import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AuthButton from "../AuthButton";
import AuthError from "../AuthError";
import { useSignupUserMutation } from "../../../redux/api/auth/signup/signupQuery";
import { useVerificationCodeMutation } from "../../../redux/api/auth/emailVerification/emailVerificationQuery";
import OTPField from "./OTPField";

import "./EmailVerificationForm.styles.css";
import { setModalVerification } from "../../../redux/modalSlice";
import { setUser } from "../../../redux/api/auth/login/loginSlice";

const EmailVerificationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorRes, setErrorRes] = useState(null);
  const [resendCountdown, setResendCountdown] = useState(30);

  const userData = useSelector((state) => state.signup.data);
  const otpCode = useSelector((state) => state.emailVerification.OTPCode);

  const hideEmail = (email) => {
    if (email) {
      const [localPart, domain] = email.split("@");
      return `${localPart[0]}********@${domain}`;
    }
    return "";
  };

  const [
    verificationCode,
    {
      data: verificationData,
      isSuccess: isVerificationSuccess,
      isLoading: isVerificationCodeLoading,
      isError: isVerificationCodeError,
      error: verificationCodeError,
    },
  ] = useVerificationCodeMutation();

  const [
    signupUser,
    {
      data: signupUserData,
      isSuccess: isSignupUserSuccess,
      isLoading: isSignupUserLoading,
      isError: isSignupUserError,
      error: signupUserError,
    },
  ] = useSignupUserMutation();

  const { handleSubmit } = useForm();

  const onSubmit = useCallback(() => {
    signupUser({ ...userData, code: parseInt(otpCode, 10) });
  }, [signupUser, userData, otpCode]);

  useEffect(() => {
    if (isSignupUserError) {
      setErrorRes(t("landing.email_verification.invalid_code"));
    }
  }, [isSignupUserError, t]);

  useEffect(() => {
    if (isSignupUserSuccess && signupUserData) {
      const typeLowerCase = signupUserData.data.user.type.toLowerCase();
      const paths = {
        CREATOR: "/auth/signup/integration",
        ADVERTISER: `/${typeLowerCase}/${signupUserData.data.user[typeLowerCase].username}`,
        AGENCY: `/${typeLowerCase}/${signupUserData.data.user[typeLowerCase].username}`,
      };
      dispatch(
        setUser({
          type: signupUserData.data.user.type,
          username: signupUserData.data.user[typeLowerCase].username,
          id: signupUserData.data.user.id,
          creator_id: signupUserData?.data?.user?.creator?.id,
        })
      );
      navigate(paths[signupUserData.data.user.type] || "/");
    }
  }, [isSignupUserSuccess, navigate, dispatch, signupUserData]);

  const handleResendCode = useCallback(() => {
    verificationCode({ email: userData?.email });
    setResendCountdown(30);
  }, [verificationCode, userData]);

  useEffect(() => {
    if (isVerificationSuccess && verificationData.ok === true) {
      dispatch(setModalVerification(true));
    }
  }, [isVerificationSuccess, verificationData, dispatch]);

  const handleError = useCallback(() => {
    setErrorRes(null);
  }, []);

  useEffect(() => {
    // Log temporal para errores en la verificación del código
    if (isVerificationCodeError) {
      console.error("Failed to resend code:", verificationCodeError);
    }
  }, [isVerificationCodeError, verificationCodeError]);

  useEffect(() => {
    // Log temporal para errores en el registro del usuario
    if (signupUserError) {
      console.error("Failed to register user:", signupUserError);
    }
  }, [signupUserError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (resendCountdown > 0) {
        setResendCountdown((prevCount) => prevCount - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [resendCountdown]);

  return (
    <form className="emailVForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="emailVFormCard">
        <h2 className="emailVFormTitle">
          {t("landing.email_verification.title")}
        </h2>
        <p className="emailVFormDescription">
          {t("landing.email_verification.description_1")}{" "}
          <label>{hideEmail(userData?.email)}</label>{" "}
          {t("landing.email_verification.description_2")}
        </p>
        <OTPField handleError={handleError} errorRes={errorRes} length={6} />
        {errorRes && <AuthError message={errorRes} />}
      </div>

      <AuthButton
        isLoading={isSignupUserLoading || isVerificationCodeLoading}
        text={t("landing.email_verification.validate_code")}
      />
      <p className="emailVFormNotReceived">
        {t("landing.email_verification.not_received_code")}
        {resendCountdown !== 0 ? (
          <label className="resendCountdown">{` ${resendCountdown
            .toString()
            .padStart(2, "0")} ${t(
            "landing.email_verification.seconds_left"
          )}`}</label>
        ) : (
          <label onClick={handleResendCode}>
            {t("landing.email_verification.resend_code")}
          </label>
        )}
      </p>
    </form>
  );
};

export default EmailVerificationForm;
