import React, { useState } from "react";
import "./KanbanTable.styles.css";
import CardOpp from "./CardOpp";
import { useDispatch, useSelector } from "react-redux";
import { setModalEditOpp } from "../../../redux/api/opportunities/opportunitiesSlice";
import CreateOpp from "../CreateOpp/CreateOpp";
import { useGetOpportunityByIdQuery } from "../../../redux/api/opportunities/opportunitiesQuery";

const KanbanTable = () => {
  const dispatch = useDispatch();
  const editOpp = useSelector((state) => state.opportunities.modalEditOpp);
  const userLoggedIn = useSelector((state) => state.login.user);
  const opportunities = useSelector(
    (state) => state.opportunities.opportunities
  );

  const [selectedId, setSelectedId] = useState(null);

  const { data: opportunityByIdData } = useGetOpportunityByIdQuery(selectedId, {
    skip: !selectedId,
    refetchOnMountOrArgChange: true,
  });

  // Obtener la oportunidad seleccionada por estado o por defecto
  const selectedOpportunity = opportunities.find(
    (opp) => opp.id === selectedId
  );
  const opportunityData = selectedOpportunity || opportunityByIdData?.data;

  const kanbanData = [
    { title: "Pendiente", status: "PENDING", colorClass: "statusColorPending" },
    {
      title: "Negociación",
      status: "NEGOTIATION",
      colorClass: "statusColorNegotiation",
    },
    {
      title: "Finalizada",
      status: "FINISHED",
      colorClass: "statusColorFinished",
    },
    {
      title: "Cancelada",
      status: "CANCELED",
      colorClass: "statusColorCanceled",
    },
  ];

  const handleEdit = (id) => {
    dispatch(setModalEditOpp(true));
    setSelectedId(id);
  };

  const handleCloseEditOpp = () => {
    dispatch(setModalEditOpp(false));
    setSelectedId(null);
  };

  return (
    <div className="kanbanTable">
      {kanbanData.map((column) => (
        <div className="kanbanTableColumn" key={column.status}>
          <div className="kanbanTableHeader">
            <span className={column.colorClass}></span>
            <p className="kanbanTableStatus">{column.title}</p>
            <p className="countOpp">{column.count}</p>
          </div>
          <div className="cardsContainer">
            {opportunities
              .filter((opp) => opp.status === column.status)
              .map((opp) => (
                <CardOpp
                  key={opp.id}
                  id={opp.id}
                  title={opp.title}
                  name={opp.advertiser_name}
                  description={opp.description}
                  status={opp.status}
                  onClick={() => handleEdit(opp.id)}
                  creators={
                    userLoggedIn.type === "AGENCY" ? opp.creators : undefined
                  }
                />
              ))}
          </div>
        </div>
      ))}
      {editOpp && (
        <CreateOpp
          onClose={handleCloseEditOpp}
          isEdit={true}
          opportunityByIdData={opportunityData}
        />
      )}
    </div>
  );
};

export default KanbanTable;
