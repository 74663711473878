import React, { useEffect } from "react";

import AnalyticsNetworks from "./AnalyticsNetworks/AnalyticsNetworks";
import AnalyticsContent from "./AnalyticsCharts/AnalyticsContent";
import AnalyticsAudience from "./AnalyticsCharts/AnalyticsAudience";

import "./Analytics.styles.css";
import { useGetAnalyticsQuery } from "../../redux/api/analytics/analyticsQuery";
import { useSelector } from "react-redux";
import { useGetCreatorQuery } from "../../redux/api/user/userQuery";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../UI/Loader/Loader";

const Analytics = () => {
  const navigate = useNavigate();
  const url = useLocation();
  const segments = url.pathname.split("/").filter(Boolean);
  const username = segments[1];

  const idNetSelected = useSelector((state) => state.analytics.idNetSelected);
  const {
    data: analyticsData,
    /* isSuccess: isAnalyticsSuccess, */
    isLoading: isAnalyticsLoading,
    isError: isAnalyticsError,
  } = useGetAnalyticsQuery(idNetSelected, {
    skip: !idNetSelected,
    refetchOnMountOrArgChange: true,
  });

  const {
    data: creatorData,
    isSuccess: isCreatorSuccess,
    isLoading: isCreatorLoading,
    isError: isCreatorError,
  } = useGetCreatorQuery(username, {
    skip: !username,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isCreatorSuccess && !creatorData?.data?.creator?.networks) {
      navigate("/");
    }
  }, [isCreatorSuccess, creatorData,  navigate]);;

  if (isCreatorError || isAnalyticsError) {
    return console.error("Report error");
  }
  return (
    <div className="analyticsPage">
      {(isCreatorLoading || isAnalyticsLoading) && <Loader />}
      <AnalyticsNetworks creatorData={creatorData?.data?.creator} />
      <AnalyticsContent analyticsContent={analyticsData?.analytics} />
      <AnalyticsAudience analyticsAudience={analyticsData?.analytics} />
    </div>
  );
};

export default Analytics;
