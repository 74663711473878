import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const userApi = createApi({
  reducerPath: "userQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getUserAvailable: builder.mutation({
      query: (data) => {
        return {
          url: `/user/available?email=${data.email}&username=${data.username}&type=${data.type}`,
          method: "GET",
        };
      },
    }),
    getUser: builder.query({
      query: () => {
        return {
          url: `/user`,
          method: "GET",
        };
      },
    }),
    getCreator: builder.query({
      query: (data) => {
        return {
          url: `/user/creator/${data}`,
          method: "GET",
        };
      },
    }),
    getAgency: builder.query({
      query: (data) => {
        return {
          url: `/user/agency/${data}`,
          method: "GET",
        };
      },
    }),
    getAdvertiser: builder.query({
      query: (data) => {
        return {
          url: `/user/advertiser/${data}`,
          method: "GET",
        };
      },
    }),
    editUser: builder.mutation({
      query: ({ type, data }) => {
        return {
          url: `/user/${type}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    editUserByAgency: builder.mutation({
      query: ({ creatorId, data }) => {
        return {
          url: `/user/agency/creator/${creatorId}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
    addCreator: builder.mutation({
      query: ({ data }) => {
        return {
          url: `/user/creator`,
          method: "POST",
          body: data,
        };
      },
    }),
    getURLAddNetwork: builder.query({
      query: (data) => {
        return {
          url: `/user/agency/creator/network/url/${data}`,
          method: "GET",
        };
      },
    }),
    getDataFromCC: builder.query({
      query: (data) => {
        return {
          url: `/user/creator/connectioncode/${data}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetUserAvailableMutation,
  useGetUserQuery,
  useGetCreatorQuery,
  useGetAdvertiserQuery,
  useGetAgencyQuery,
  useEditUserMutation,
  useEditUserByAgencyMutation,
  useAddCreatorMutation,
  useGetURLAddNetworkQuery,
  useGetDataFromCCQuery,
  middleware,
} = userApi;

export default userApi.reducer;
