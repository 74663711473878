import info from "../../../../assets/icons/IconInfo.svg";

import youtube from "../../../../assets/icons/IconYoutube.svg";
import tiktok from "../../../../assets/icons/IconTiktok.svg";
import instagram from "../../../../assets/icons/IconInstagramLine.svg";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardInfo,
  CardTitle,
} from "../cardCharts/CardCharts";
import BarBasicChart from "./ChartBar";
import Tooltip from "../../Tooltip/Tooltip";
import EmptyState from "../emptyState/EmptyState";

const ChartCardBar = ({
  title,
  data,
  xAxisKey,
  bars,
  isHorizontal,
  tooltipDescription,
  network,
}) => {
  const isDataEmpty = !data || data.length === 0;
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <p style={{ width: "max-content" }}>{title}</p>
        </CardTitle>
        <CardInfo>
          <Tooltip content={tooltipDescription} side="bottom">
            <img src={info} alt="info" />
          </Tooltip>
          <img
            src={
              network === "YOUTUBE"
                ? youtube
                : network === "TIKTOK"
                ? tiktok
                : instagram
            }
            alt="network"
          />
        </CardInfo>
      </CardHeader>
      <CardContent>
        {isDataEmpty ? (
          <EmptyState />
        ) : (
          <div style={{ width: "100%", height: 300, display: "flex" }}>
            <BarBasicChart
              isHorizontal={isHorizontal}
              data={data}
              xAxisKey={xAxisKey}
              bars={bars}
            />
          </div>
        )}
      </CardContent>
      {!isDataEmpty && (
        <CardFooter>
          {bars.map((bar) => (
            <div key={bar.dataKey}>
              <div style={{ backgroundColor: bar.fill }} />
              <p>{bar.dataKey}</p>
            </div>
          ))}
        </CardFooter>
      )}
    </Card>
  );
};

export default ChartCardBar;
