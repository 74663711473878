import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "./NewChatModal.styles.css";
import {
  setModalAddConversation,
  updateConversationField,
  updateSelectedConversation,
  updateSelectedConversationChat,
} from "../../../../redux/api/conversations/conversationsSlice";
import {
  useAddConversationMutation,
  useUpdateConversationByIdMutation,
} from "../../../../redux/api/conversations/conversationsQuery";
import { Controller, useForm } from "react-hook-form";
import warning from "../../../../assets/icons/IconWarning.svg";
import { useEffect } from "react";
import { updateUserAgencyField } from "../../../../redux/api/user/userAgencySlice";
import { updateUserCreatorField } from "../../../../redux/api/user/userCreatorSlice";

const NewChatModal = ({ userId, conversationId, isAgency }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userLoggedIn = useSelector((state) => state.login.user);
  const conversation = useSelector(
    (state) => state.conversations.selectedConversation
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [addConversation, { isSuccess: isAddConversationSuccess }] =
    useAddConversationMutation();

  const [
    updateConversationById,
    { data: updateConversationsData, isSuccess: isUpdateConversationsSuccess },
  ] = useUpdateConversationByIdMutation();

  const onSubmit = (e) => {
    if (conversationId) {
      updateConversationById({
        id: conversationId,
        status: "INIT_APPROVAL_PENDING",
        ...e,
      });
    } else {
      addConversation({
        ...e,
        receiver_user_id: userId,
      });
    }
  };
  const handleClose = () => {
    dispatch(
      setModalAddConversation({
        modal: false,
        userId: null,
        conversationId: null,
        isAgency: false,
      })
    );
  };

  useEffect(() => {
    if (isAddConversationSuccess) {
      dispatch(
        setModalAddConversation({
          modal: false,
          userId: null,
          conversationId: null,
        })
      );

      if (userLoggedIn.type === "ADVERTISER" && !isAgency) {
        dispatch(
          updateUserCreatorField({
            field: "has_conversation_with_visitor",
            value: true,
          })
        );
      } else if (userLoggedIn.type === "ADVERTISER" && isAgency) {
        dispatch(
          updateUserAgencyField({
            field: "has_conversation_with_visitor",
            value: true,
          })
        );
      }
    }
  }, [isAddConversationSuccess, dispatch]);

  useEffect(() => {
    if (isUpdateConversationsSuccess) {
      const { status } = updateConversationsData.conversation;

      if (status === "INIT_APPROVAL_PENDING") {
        const updateStatus = () => {
          dispatch(
            updateConversationField({
              id: conversationId,
              field: "status",
              value: status,
            })
          );
          dispatch(
            updateSelectedConversation({
              field: "status",
              value: status,
            })
          );
        };
        updateStatus();
        const messageObj = {
          conversation_id: updateConversationsData.conversation.id,
          sender_user_id: userLoggedIn.id,
          receiver_user_id: conversation.advertiser
            ? conversation.advertiser.user_id
            : conversation.creator
            ? conversation.creator.user_id
            : conversation.agency.user_id,
          content: updateConversationsData.conversation.message,
          type: "REGULAR",
        };
        dispatch(updateSelectedConversationChat(messageObj));
      }
      dispatch(
        setModalAddConversation({
          modal: false,
          userId: null,
          conversationId: null,
        })
      );
    }
  }, [
    isUpdateConversationsSuccess,
    updateConversationsData,
    dispatch,
    conversationId,
    userLoggedIn,
    conversation,
    updateConversationById,
  ]);

  return (
    <div className="modalAddConversation">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modalAddConversation__container">
          <p className="modalAddConversation__title">
            {t("profile.init_talk.title")}
          </p>
          <p className="modalAddConversation__subtitle">
            {!isAgency
              ? t("profile.init_talk.paragraph_1")
              : t("profile.init_talk.paragraph_2")}
          </p>
          <div className="modalAddConversationMessage">
            <div className="modalAddConversationMessageTitle">
              <label></label>
              <span>
                {t("onboarding.personal_information.description.desc")}
              </span>
            </div>
            <Controller
              name="message"
              control={control}
              rules={{
                maxLength: {
                  value: 300,
                  message: t("chat.messages.message_max_length"),
                },
                required: {
                  value: true,
                  message: t("chat.messages.message_required"),
                },
              }}
              render={({ field }) => (
                <textarea
                  className="modalAddConversationTextarea"
                  placeholder={t("profile.init_talk.placeholder")}
                  {...field}
                ></textarea>
              )}
            />
            {errors.message && (
              <span className="formSpamError">
                <img src={warning} alt="warning" height={16} width={16} />
                {errors.message.message}
              </span>
            )}
          </div>
          <div className="modalAddConversation__buttons">
            <button
              type="submit"
              className={`${
                errors.message ? "sendMessage__disabled" : "sendMessage"
              }`}
              disabled={errors.message ? true : false}
            >
              {t("chat.messages.btn_send")}
            </button>
            <button onClick={handleClose}>{t("general.btn_cancel")}</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewChatModal;
