import { useTranslation } from "react-i18next";


import noData from "../../../../assets/images/undraw-no-data.svg";
import "./EmptyState.css";

const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <div className="emptyStateContent">
      <div className="emptyStateContentInfo">
        <img src={noData} alt="no-data" />
        <p>{t("analytics.empty_state")}</p>
      </div>
    </div>
  );
};

export default EmptyState;
