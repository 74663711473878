import React, { useEffect } from "react";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";
import Profile from "../../components/Profile/Profile";
import "./ProfilePage.styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserQuery } from "../../redux/api/user/userQuery";
import { updateUserLoggedField } from "../../redux/api/auth/login/loginSlice";

const ProfilePage = () => {
  const dispatch = useDispatch();
  const userLoggedIn = useSelector((state) => state.login.user);

  const { data: userData, isSuccess: isDataUserSuccess } = useGetUserQuery(
    userLoggedIn?.id,
    {
      skip: !userLoggedIn,
    }
  );

  useEffect(() => {
    if (isDataUserSuccess && !userLoggedIn) {
      dispatch(
        updateUserLoggedField({
          field: "username",
          value: userData?.data.user.username,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "email",
          value: userData?.data.user.email,
        })
      );
      dispatch(
        updateUserLoggedField({
          field: "type",
          value: userData?.data.user.type,
        })
      );
    }
  }, [isDataUserSuccess, userData, dispatch]);

  return (
    <div className="profilePageContainer">
      <HeaderIn />
      <Profile />
    </div>
  );
};

export default ProfilePage;
