import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import esTranslation from "./es.json";
import enTranslation from "./en.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  es: {
    translation: esTranslation,
  },
  en: {
    translation: enTranslation,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "es",
    detection: {
      order: [],
      caches: [],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
