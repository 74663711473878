import { useLoginUserMutation } from "../../../redux/api/auth/login/loginQuery";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import AuthField from "../AuthField";
import AuthButton from "../AuthButton";
import AuthError from "../AuthError";
import "./SignInForm.styles.css";
import { signinSchema } from "../../../validationsSchema/signinSchema";
import { useDispatch } from "react-redux";
import { setModalError } from "../../../redux/modalSlice";
import { setUser } from "../../../redux/api/auth/login/loginSlice";
import { useNavigate } from "react-router-dom";

const SignInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [errorRes, setErrorRes] = useState(null);
  const { t } = useTranslation();
  const [
    loginUser,
    {
      data: loginData,
      isSuccess: isLoginSuccess,
      isLoading: isLoginLoading,
      isError: isLoginError,
      error: loginError,
    },
  ] = useLoginUserMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: zodResolver(signinSchema(t)),
  });

  const onSubmit = async (data) => {
    setLoader(true);
    setErrorRes(null);
    try {
      await loginUser(data).unwrap();
    } catch (error) {
      console.error("Failed to login: ", error);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (isLoginError && loginError?.data) {
      const { error } = loginError.data;
      const invalidCredentials = error === "INVALID_CREDENTIALS";

      if (invalidCredentials) {
        setErrorRes(t("landing.sign_up.invalid_credentials"));
      } else {
        dispatch(setModalError(true));
      }
    }
  }, [isLoginError, loginError, t, dispatch]);

  useEffect(() => {
    if (isLoginSuccess && loginData?.user) {
      const { type, creator } = loginData.user;
      const typeLowerCase = loginData.user.type.toLowerCase();
      const userProfilePath = `/${type.toLowerCase()}/${
        loginData.user[typeLowerCase].username
      }`;

      dispatch(
        setUser({
          type: loginData.user.type,
          username: loginData.user[typeLowerCase].username,
          id: loginData.user.id,
          creator_id: loginData?.user?.creator?.id,
        })
      );
      navigate(userProfilePath);
    }
  }, [isLoginSuccess, loginData, navigate, dispatch]);

  const handleInputChange = (field) => {
    clearErrors(field);
    setErrorRes(null);
  };

  return (
    <form className="signInForm" onSubmit={handleSubmit(onSubmit)}>
      <div className="signInFormCard">
        <h2 className="signInFormTitle">{t("landing.sign_in.title")}</h2>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <AuthField
              label={t("landing.sign_up.email.label")}
              placeholder={t("landing.sign_up.email.placeholder")}
              type="text"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("email");
              }}
              error={errors.email}
              errorRes={errorRes}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <AuthField
              label={t("landing.sign_up.password.label")}
              placeholder={t("landing.sign_up.password.placeholder")}
              type="password"
              value={field.value || ""}
              onChange={(e) => {
                field.onChange(e);
                handleInputChange("password");
              }}
              error={errors.password}
              errorRes={errorRes}
            />
          )}
        />
        {errorRes && <AuthError message={errorRes} />}
      </div>
      <AuthButton
        isLoading={isLoginLoading || loader}
        text={t("landing.sign_in.btn_signin")}
      />
      <p className="signInFormNotRegistered">
        {t("landing.sign_in.not_registered")}
        <a href="/auth/signup">{t("landing.sign_up.btn_signup")}</a>
      </p>
    </form>
  );
};

export default SignInForm;
