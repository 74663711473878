import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChartCardArea from "../../../UI/Charts/area/ChartCardArea";
import ChartCardBar from "../../../UI/Charts/bar/ChartCardBar";

import "../AnalyticsCharts.styles.css";

const ContentTK = ({ analyticsContent }) => {
  const { t } = useTranslation();

  const analytics = analyticsContent?.data?.analytics;
  const platform = useSelector((state) => state.analytics.platformSelected);

  const formatWeekDate = (weekStr, t) => {
    const [startDate, endDate] = weekStr.split(" - ");

    // Aquí obtenemos los nombres de los meses de manera dinámica con traducciones
    const monthNames = Array.from({ length: 12 }, (_, i) =>
      t(`analytics.yt.dimensions.month.${i + 1}`)
    );

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      const day = date.getDate();
      const month = date.getMonth();
      return `${day} ${monthNames[month]?.slice(0, 3)}`;
    };

    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  // Extraer datos de la dimensión "month"
  const weeklyData =
    analytics?.find((item) => item.dimension === "week")?.data || [];

  const transformedWeeklyData = weeklyData?.map((item) => ({
    week: formatWeekDate(item.week, t),
    [t("analytics.tk.metrics.video_views")]: item.video_views,
    [t("analytics.tk.metrics.unique_video_views")]: item.unique_video_views,
    [t("analytics.tk.metrics.likes")]: item.likes,
    [t("analytics.tk.metrics.comments")]: item.comments,
    [t("analytics.tk.metrics.shares")]: item.shares,
  }));

  // Configuración para el gráfico de área
  const areas = [
    { dataKey: t("analytics.tk.metrics.video_views"), color: "#FD2C55" },
    { dataKey: t("analytics.tk.metrics.unique_video_views"), color: "#25F3ED" },
  ];

  // Configuración para los gráficos de barras
  const barsLikes = [
    { dataKey: t("analytics.tk.metrics.likes"), fill: "#25F3ED", radius: 4 },
  ];
  const barsComments = [
    { dataKey: t("analytics.tk.metrics.comments"), fill: "#FD2C55", radius: 4 },
  ];

  const barsShares = [
    { dataKey: t("analytics.tk.metrics.shares"), fill: "#000", radius: 4 },
  ];

  return (
    <>
      {/* Gráfico de área para Views */}
      <ChartCardArea
        title={t("analytics.tk.dimensions.week.title")}
        data={transformedWeeklyData}
        xAxisKey="week"
        areas={areas}
        tooltipDescription={t("analytics.tk.dimensions.week.tooltip")}
        network={platform}
      />
      <div className="analyticsChartsContentBars">
        {/* Gráfico de barras para Likes */}
        <ChartCardBar
          title={t("analytics.tk.dimensions.likes.title")}
          data={transformedWeeklyData}
          xAxisKey="week"
          bars={barsLikes}
          isHorizontal={true}
          tooltipDescription={t("analytics.tk.dimensions.likes.tooltip")}
          network={platform}
        />
      </div>
      <div className="analyticsChartsContentBars">
        {/* Gráfico de barras para Comentarios */}
        <ChartCardBar
          title={t("analytics.tk.dimensions.comments.title")}
          data={transformedWeeklyData}
          xAxisKey="week"
          bars={barsComments}
          isHorizontal={true}
          tooltipDescription={t("analytics.tk.dimensions.comments.tooltip")}
          network={platform}
        />
        {/* Gráfico de barras para cómo se comparte */}
        <ChartCardBar
          title={t("analytics.tk.metrics.shares")}
          data={transformedWeeklyData}
          xAxisKey="week"
          bars={barsShares}
          isHorizontal={true}
          tooltipDescription={t("analytics.tk.dimensions.shares.tooltip")}
          network={platform}
        />
      </div>
    </>
  );
};

export default ContentTK;
