import { useEffect } from "react";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";
import Opportunities from "../../components/Opportunities/Opportunities";
import "./OpportunitiesPage.styles.css";
import { useGetOpportunityQuery } from "../../redux/api/opportunities/opportunitiesQuery";
import { useDispatch } from "react-redux";
import { setOpportunitiesData } from "../../redux/api/opportunities/opportunitiesSlice";

const OpportunitiesPage = () => {
  const dispatch = useDispatch();
  const {
    data: opportunityData,
    isSuccess,
    isLoading: isOpportunityLoading,
  } = useGetOpportunityQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: false,
    keepUnusedDataFor: 0,
  });

  useEffect(() => {
    if (isSuccess && opportunityData?.data) {
      dispatch(setOpportunitiesData(opportunityData.data));
    }
  }, [isSuccess, opportunityData, dispatch]);

  return (
    <div className="opportunitiesContainer">
      <HeaderIn />
      <Opportunities isLoading={isOpportunityLoading} />
    </div>
  );
};

export default OpportunitiesPage;
