import Analytics from "../../components/Analytics/Analytics";
import HeaderIn from "../../components/UI/HeaderIn/HeaderIn";

import "./AnalyticsPage.styles.css";

const AnalyticsPage = () => {
  return (
    <div className="analyticsPageContainer">
      <HeaderIn />
      <Analytics />
    </div>
  );
};

export default AnalyticsPage;
