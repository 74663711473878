import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import ChartTooltipContent from "../tooltip/ChartTooltipContent";

const AreaGradientChart = ({
  data = [],
  areas = [],
  xAxisKey,
  margin = { top: 10, right: 30, left: 10, bottom: 25 },
}) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} margin={margin}>
        <defs>
          {areas.map((area, index) => (
            <linearGradient
              key={index}
              id={`color${area.dataKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={area.color} stopOpacity={1} />
              <stop offset="100%" stopColor={area.color} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          tickMargin={32}
          tickLine={false}
          axisLine={false}
          tickCount={10}
          dataKey={xAxisKey}
          tickFormatter={(value) =>
            xAxisKey === "week" ? value : value.slice(0, 3)
          }
          allowDecimals={false}
        />
        <YAxis
          tickMargin={16}
          tickLine={false}
          axisLine={false}
          allowDecimals={false}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip content={<ChartTooltipContent />} />
        {areas.map((area, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={area.dataKey}
            stroke={area.color}
            fillOpacity={1}
            fill={`url(#color${area.dataKey})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaGradientChart;
