import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalConversation: {
    modal: false,
    userId: null,
    conversationId: null,
    isAgency: false,
  },
  modalFinishConversation: {
    modal: false,
    conversationId: null,
    type: null,
  },
  conversations: null,
  selectedIdConversation: null,
  selectedConversation: null,
  selectedConversationChat: null,
};

export const conversationsSlice = createSlice({
  name: "conversations",
  initialState,
  reducers: {
    setModalAddConversation: (state, action) => {
      state.modalConversation = action.payload;
    },
    setModalFinishConversation: (state, action) => {
      state.modalFinishConversation = action.payload;
    },
    setAllConversations: (state, action) => {
      state.conversations = action.payload;
    },
    updateConversationField: (state, action) => {
      const { id, field, value } = action.payload;
      const conversationIndex = state.conversations.findIndex(
        (conversation) => conversation.id === id
      );

      if (conversationIndex !== -1) {
        state.conversations[conversationIndex] = {
          ...state.conversations[conversationIndex],
          [field]: value,
        };
      }
    },
    setSelectedIdConversation: (state, action) => {
      state.selectedIdConversation = action.payload;
    },

    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    updateSelectedConversation: (state, action) => {
      const { field, value } = action.payload;
      if (state.selectedConversation) {
        state.selectedConversation[field] = value;
      }
    },

    updateMessageInitializerField: (state, action) => {
      const { id, field, value } = action.payload;
      const conversationIndex = state.selectedConversationChat.findIndex(
        (conversation) => conversation.id === id
      );

      if (conversationIndex !== -1) {
        state.selectedConversationChat[conversationIndex] = {
          ...state.selectedConversationChat[conversationIndex],
          [field]: value,
        };
      }
    },
    updateSelectedConversationChat: (state, action) => {
      state.selectedConversationChat = [
        ...state.selectedConversationChat,
        action.payload,
      ];
    },
    setSelectedConversationChat: (state, action) => {
      state.selectedConversationChat = action.payload;
    },
    clearSelectedConversation: (state) => {
      state.selectedConversation = null;
      state.selectedConversationChat = null;
    },
  },
});

export default conversationsSlice.reducer;
export const {
  setModalAddConversation,
  setModalFinishConversation,
  setAllConversations,
  setSelectedIdConversation,
  setSelectedConversation,
  updateConversationField,
  updateSelectedConversation,
  updateMessageInitializerField,
  updateSelectedConversationChat,
  setSelectedConversationChat,
  clearSelectedConversation,
} = conversationsSlice.actions;
