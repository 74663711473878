import React, { useEffect, useState } from "react";
import "./Profile.styles.css";
import ProfileReputation from "./ProfileReputation";
import {
  useGetAdvertiserQuery,
  useGetAgencyQuery,
  useGetCreatorQuery,
} from "../../redux/api/user/userQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditProfile from "./editProfile/Edit";
import { setEdit } from "../../redux/api/user/userSlice";
import { setUserCreatorData } from "../../redux/api/user/userCreatorSlice";
import {
  setAddCreator,
  setUserAgencyData,
} from "../../redux/api/user/userAgencySlice";
import { setUserAdvertiserData } from "../../redux/api/user/userAdvertiserSlice";
import ProfileCardCreator from "./creator/ProfileCardCreator";
import ProfileCardAgency from "./agency/ProfileCardAgency";
import ProfileCardAdvertiser from "./advertiser/ProfileCardAdvertiser";
import ProfileAboutCreator from "./creator/ProfileAboutCreator";
import ProfileAboutAgency from "./agency/ProfileAboutAgency";
import ProfileAboutAdvertiser from "./advertiser/ProfileAboutAdvertiser";
import ProfileCreatorsAgency from "./agency/ProfileCreatorsAgency";
import ProfileNetworksCreator from "./creator/ProfileNetworksCreator";
import AddCreators from "./agency/AddCreators/AddCreators";
import NetworkModal from "../UI/ModalMessages/NetworkModal/NetworkModal";
import { setModalAddNetwork } from "../../redux/api/network/networkSlice";
import DeleteNetModal from "../UI/ModalMessages/DeleteNetModal/DeleteNetModal";
import { useTranslation } from "react-i18next";
import InformationModal from "../UI/ModalMessages/InformationModal/InformationModal";
import {
  setModalErrorEditUser,
  setModalErrorNetwork,
  setModalSuccessNetwork,
} from "../../redux/modalSlice";
import NewChatModal from "../UI/ModalMessages/NewChatModal/NewChatModal";
import ProfileAnalyticsCreator from "./creator/ProfileAnalyticsCreator";
import Error from "../../components/UI/Error/Error";
import Loader from "../../components/UI/Loader/Loader";

const Profile = () => {
  const { t } = useTranslation();
  const edit = useSelector((state) => state.user.edit);
  const addCreator = useSelector((state) => state.userAgency.addCreator);
  const addNetwork = useSelector((state) => state.network.modalNetwork);
  const platform = useSelector((state) => state.network.platform);
  const modalDelete = useSelector((state) => state.modal.modalDeleteNet);
  const modalError = useSelector((state) => state.modal.modalNetwork);
  const modalSuccess = useSelector((state) => state.modal.modalSuccessNetwork);
  const modalErrorEditUser = useSelector(
    (state) => state.modal.modalErrorEditUser
  );
  const modalNewChat = useSelector(
    (state) => state.conversations.modalConversation
  );

  const [modalAlreadyConnected, setModalAlreadyConnected] = useState(false);
  const [modalErrorUndefined, setModalErrorUndefined] = useState(false);
  const [modalData, setModalData] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const url = useLocation();

  const segments = url.pathname.split("/").filter(Boolean);
  const type = segments[0];
  const username = segments[1];

  // Condiciona las queries
  const {
    data: creatorData,
    isLoading: isCreatorLoading,
    isError: isCreatorError,
  } = useGetCreatorQuery(username, {
    skip: type !== "creator",
    refetchOnMountOrArgChange: true,
  });

  const {
    data: advertiserData,
    isLoading: isAdvertiserLoading,
    isError: isAdvertiserError,
  } = useGetAdvertiserQuery(username, {
    skip: type !== "advertiser",
    refetchOnMountOrArgChange: true,
  });

  const {
    data: agencyData,
    isLoading: isAgencyLoading,
    isError: isAgencyError,
  } = useGetAgencyQuery(username, {
    skip: type !== "agency",
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const checkDataAvailability = () => {
      if (type && username) {
        if (type === "creator" && !creatorData) {
          <div>No encontrado</div>;
        } else if (type === "advertiser" && !advertiserData) {
          <div>No encontrado</div>;
        } else if (type === "agency" && !agencyData) {
          <div>No encontrado</div>;
        }
      }
    };

    if (!isCreatorLoading && !isAdvertiserLoading && !isAgencyLoading) {
      checkDataAvailability();
    }
  }, [
    type,
    username,
    creatorData,
    advertiserData,
    agencyData,
    isCreatorLoading,
    isAdvertiserLoading,
    isAgencyLoading,
    navigate,
  ]);

  // seteo en redux data del creador
  useEffect(() => {
    if (creatorData) {
      dispatch(setUserCreatorData(creatorData.data.creator));
    }
  }, [creatorData, dispatch]);

  // seteo en redux data de la marca
  useEffect(() => {
    if (advertiserData) {
      dispatch(setUserAdvertiserData(advertiserData.data.advertiser));
    }
  }, [advertiserData, dispatch]);

  // seteo en redux data de la agencia
  useEffect(() => {
    if (agencyData) {
      dispatch(setUserAgencyData(agencyData.data.agency));
    }
  }, [agencyData, dispatch]);

  useEffect(() => {
    if (modalError.modal && modalError.message) {
      if (modalError.message === "Account already connected.") {
        setModalAlreadyConnected(true);
        setModalData({
          icon: "warning",
          title: t("profile.networks.integration.error"),
          text: t("profile.networks.integration.already_integrated"),
          textButtonClose: t("general.btn_return"),
        });
      } else {
        setModalErrorUndefined(true);
        setModalData({
          icon: "warning",
          title: t("profile.networks.integration.error"),
          text: t("profile.networks.integration.error_add_network"),
          textButtonClose: t("general.btn_return"),
        });
      }
    }
  }, [modalError.message, modalError.modal, t]);

  if (isCreatorError) {
    return <Error type={"creator"} />;
  } else if (isAdvertiserError) {
    return <Error type={"advertiser"} />;
  } else if (isAgencyError) {
    return <Error type={"agency"} />;
  }

  const handleEditClose = () => {
    dispatch(setEdit(false));
  };
  const handleCreateClose = () => {
    dispatch(setAddCreator(false));
  };

  const modalDataSuccess = {
    icon: "check",
    title: t("profile.networks.integration.ok"),
    text: t("profile.networks.integration.success"),
    textButtonClose: t("general.btn_return"),
  };
  const modalDataErrorEditUser = {
    icon: "warning",
    title: t("profile.edit.error_undefined_title"),
    text: t("profile.edit.error_undefined_subtitle"),
    textButtonClose: t("general.btn_return"),
  };

  const handleClose = () => {
    dispatch(setModalErrorNetwork({ modal: false, message: null }));
    setModalAlreadyConnected(false);
    setModalErrorUndefined(false);
    dispatch(setModalSuccessNetwork(false));
    dispatch(setModalErrorEditUser({ modal: false, message: null }));
  };

  return (
    <div className="profilePage">
      {isCreatorLoading ||
        isAdvertiserLoading ||
        (isAgencyLoading && <Loader />)}
      {type === "creator" && (
        <>
          <ProfileCardCreator userType={type} />
          <ProfileAboutCreator />
          <div className="profileNetRep">
            <ProfileNetworksCreator userType={type} />
            <ProfileAnalyticsCreator userType={type} />
          </div>
        </>
      )}
      {type === "agency" && (
        <>
          <ProfileCardAgency userType={type} />
          <ProfileAboutAgency />
          <div className="profileNetRep">
            <ProfileCreatorsAgency userType={type} />
            <ProfileReputation />
          </div>
        </>
      )}
      {type === "advertiser" && (
        <>
          <ProfileCardAdvertiser userType={type} />
          <ProfileAboutAdvertiser />
          <div className="profileNetRep">
            <ProfileReputation />
          </div>
        </>
      )}

      {edit && <EditProfile userType={type} onClose={handleEditClose} />}
      {addCreator && (
        <AddCreators userType={type} onClose={handleCreateClose} />
      )}
      {addNetwork && (
        <NetworkModal
          platform={platform}
          onClose={() => dispatch(setModalAddNetwork(false))}
        />
      )}
      {modalDelete.modal && (
        <DeleteNetModal id={modalDelete.id} name={modalDelete.name} />
      )}
      {modalAlreadyConnected && (
        <InformationModal
          data={modalData}
          onClose={handleClose}
          onAccept={null}
        />
      )}
      {modalErrorUndefined && (
        <InformationModal
          data={modalData}
          onClose={handleClose}
          onAccept={null}
        />
      )}
      {modalSuccess && (
        <InformationModal
          data={modalDataSuccess}
          onClose={handleClose}
          onAccept={null}
        />
      )}
      {modalErrorEditUser.modal && (
        <InformationModal
          data={modalDataErrorEditUser}
          onClose={handleClose}
          onAccept={null}
        />
      )}
      {modalNewChat.modal && (
        <NewChatModal
          userId={modalNewChat.userId}
          conversationId={modalNewChat.conversationId}
          isAgency={modalNewChat.isAgency}
        />
      )}
    </div>
  );
};

export default Profile;
