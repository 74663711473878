import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChartCardArea from "../../../UI/Charts/area/ChartCardArea";
import ChartCardBar from "../../../UI/Charts/bar/ChartCardBar";

import "../AnalyticsCharts.styles.css";

const ContentYT = ({ analyticsContent }) => {
  const { t } = useTranslation();

  const platform = useSelector((state) => state.analytics.platformSelected);
  const analytics = analyticsContent?.data?.analytics;

  // Función para traducir valores del backend
  const translateValue = (dimension, value) => {
    return t(`analytics.yt.dimensions.${dimension}.${value}`, value);
  };

  // Mapeo de números de mes a nombres
  const monthNames = Array.from({ length: 12 }, (_, i) =>
    t(`analytics.yt.dimensions.month.${i + 1}`)
  );

  // Extraer datos de la dimensión "month"
  const monthlyData =
    analytics?.find((item) => item.dimension === "month")?.data || [];

  const transformedMonthlyData = monthlyData.map((item) => {
    const monthNumber = parseInt(item.month.split("-")[1], 10);
    return {
      month: monthNames[monthNumber - 1], // Convertir el número a nombre de mes
      [t("analytics.yt.metrics.views")]: item.views,
      [t("analytics.yt.metrics.likes")]: item.likes,
      [t("analytics.yt.metrics.comments")]: item.comments,
      [t("analytics.yt.dimensions.average_view_duration.unit")]: (
        item.averageViewDuration / 60
      ).toFixed(0),
    };
  });

  // Extraer datos de la dimensión "sharingService"
  const sharingServiceData =
    analytics?.find((item) => item.dimension === "sharingService")?.data || [];
  const transformedSharingServiceData = sharingServiceData.map((item) => ({
    service: translateValue("sharing_service", item.sharingService),
    [t("analytics.yt.metrics.shares")]: item.shares,
  }));

  // Configuración para el gráfico de área
  const areas = [
    { dataKey: t("analytics.yt.metrics.views"), color: "#FF0033" },
  ];

  // Configuración para los gráficos de barras
  const barsLikes = [
    { dataKey: t("analytics.yt.metrics.likes"), fill: "#FF0033", radius: 4 },
  ];
  const barsComments = [
    { dataKey: t("analytics.yt.metrics.comments"), fill: "#000000", radius: 4 },
  ];

  const barsSharingService = [
    { dataKey: t("analytics.yt.metrics.shares"), fill: "#FF0033", radius: 4 },
  ];

  const barsAverageViewDuration = [
    {
      dataKey: t("analytics.yt.dimensions.average_view_duration.unit"),
      fill: "#FF0033",
      radius: 4,
    },
  ];
  return (
    <>
      {/* Gráfico de área para Views */}
      <ChartCardArea
        title={t("analytics.yt.dimensions.month.title")}
        data={transformedMonthlyData}
        xAxisKey="month"
        areas={areas}
        tooltipDescription={t("analytics.yt.dimensions.month.tooltip")}
        network={platform}
      />
      <div className="analyticsChartsContentBars">
        {/* Gráfico de barras para Likes */}
        <ChartCardBar
          title={t("analytics.yt.dimensions.likes.title")}
          data={transformedMonthlyData}
          xAxisKey="month"
          bars={barsLikes}
          isHorizontal={true}
          tooltipDescription={t("analytics.yt.dimensions.likes.tooltip")}
          network={platform}
        />
        {/* Gráfico de barras para Comentarios */}
        <ChartCardBar
          title={t("analytics.yt.dimensions.comments.title")}
          data={transformedMonthlyData}
          xAxisKey="month"
          bars={barsComments}
          isHorizontal={true}
          tooltipDescription={t("analytics.yt.dimensions.comments.tooltip")}
          network={platform}
        />
      </div>
      <div className="analyticsChartsContentBars">
        {/* Gráfico de barras para cómo se comparte */}
        <ChartCardBar
          title={t("analytics.yt.dimensions.sharing_service.title")}
          data={transformedSharingServiceData}
          xAxisKey="service"
          bars={barsSharingService}
          isHorizontal={false}
          tooltipDescription={t(
            "analytics.yt.dimensions.sharing_service.tooltip"
          )}
          network={platform}
        />
        {/* Gráfico de barras para Duración Promedio de Visualización */}
        <ChartCardBar
          title={t("analytics.yt.dimensions.average_view_duration.title")}
          data={transformedMonthlyData}
          xAxisKey="month"
          bars={barsAverageViewDuration}
          isHorizontal={true}
          tooltipDescription={t(
            "analytics.yt.dimensions.average_view_duration.tooltip"
          )}
          network={platform}
        />
      </div>
    </>
  );
};

export default ContentYT;
