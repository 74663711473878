import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import warning from "../../../assets/icons/IconWarning.svg";
import person from "../../../assets/icons/IconUsername.svg";
import close from "../../../assets/icons/IconClose.svg";
import edit from "../../../assets/icons/IconEditWhite.svg";
import creatorProfileImg from "../../../assets/images/creator_default_img.jpg";
import agencyProfileImg from "../../../assets/images/agency_default_img.jpg";
import advertiserProfileImg from "../../../assets/images/advertiser_default_img.jpg";
import "./Edit.styles.css";
import { useTranslation } from "react-i18next";
import { useSetImgProfileMutation } from "../../../redux/api/user/userImgQuery";
import {
  useEditUserByAgencyMutation,
  useEditUserMutation,
} from "../../../redux/api/user/userQuery";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserCreatorField } from "../../../redux/api/user/userCreatorSlice";
import { updateUserAgencyField } from "../../../redux/api/user/userAgencySlice";
import { updateUserAdvertiserField } from "../../../redux/api/user/userAdvertiserSlice";
import { updateUserLoggedField } from "../../../redux/api/auth/login/loginSlice";
import { useGetTagsQuery } from "../../../redux/api/tag/tagQuery";
import { setModalErrorEditUser } from "../../../redux/modalSlice";

const EditProfile = ({ userType, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLoggedIn = useSelector((state) => state.login.user);
  const loggedInType = userLoggedIn?.type?.toLowerCase() || "";
  const creatorRedux = useSelector((state) => state.userCreator.creator);
  const agencyRedux = useSelector((state) => state.userAgency.agency);
  const advertiserRedux = useSelector(
    (state) => state.userAdvertiser.advertiser
  );
  const [errorEditUsername, setErrorEditUsername] = useState(false);

  const {
    data: tagsData,
    /* isLoading,
    isError,
    error, */
  } = useGetTagsQuery(undefined, {
    skip: userType !== "creator",
    refetchOnMountOrArgChange: true,
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      username:
        userType === "creator"
          ? creatorRedux?.username
          : userType === "agency"
          ? agencyRedux?.username
          : userType === "advertiser"
          ? advertiserRedux?.username
          : "",
      description:
        userType === "creator"
          ? creatorRedux?.description
          : userType === "agency"
          ? agencyRedux?.description
          : userType === "advertiser"
          ? advertiserRedux?.description
          : "",
    },
  });

  const [fileImg, setFileImg] = useState(null);
  const [dataForm, setDataForm] = useState(null);
  const [tags, setTags] = useState([]);

  const [selectedTags, setSelectedTags] = useState(creatorRedux?.tags || []);

  const [profileImage, setProfileImage] = useState(
    userType === "creator"
      ? creatorRedux?.profile_img_url
      : userType === "agency"
      ? agencyRedux?.profile_img_url
      : userType === "advertiser"
      ? advertiserRedux?.profile_img_url
      : undefined
  );

  const [errorImg, setErrorImg] = useState(false);

  const [
    setImgProfile,
    {
      data: profileImgData,
      isSuccess: isProfileImgSuccess,
      isError: isProfileImgError,
    },
  ] = useSetImgProfileMutation();
  const [
    editUser,
    {
      data: editUserData,
      isSuccess: isEditUserSuccess,
      isError: isEditUserError,
      error: editUserError,
    },
  ] = useEditUserMutation();
  const [
    editUserByAgency,
    {
      data: editUserByAgencyData,
      isSuccess: isEditUserByAgencySuccess,
      isError: isEditUserByAgencyError,
      error: editUserByAgencyError,
    },
  ] = useEditUserByAgencyMutation();

  const handleTagClick = (tag) => {
    if (selectedTags.length < 5) {
      setSelectedTags([...selectedTags, tag]);
      setTags(tags.filter((t) => t !== tag));
    }
  };

  const handleSelectedTagClick = (tag) => {
    setTags([...tags, tag]);
    setSelectedTags(selectedTags.filter((t) => t !== tag));
  };

  const handleImageChange = (e) => {
    setErrorImg(false);
    const file = e.target.files[0];
    setFileImg(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onSubmitForm = (data) => {
    const imgFormData = new FormData();
    imgFormData.append("file", fileImg);
    let formData = { ...data };

    const defaultUsername =
      userType === "creator"
        ? creatorRedux?.username
        : userType === "agency"
        ? agencyRedux?.username
        : userType === "advertiser"
        ? advertiserRedux?.username
        : "";

    if (data.username === defaultUsername) {
      const { username, ...formDataWithoutUsername } = formData;
      formData = formDataWithoutUsername;
    }

    // Si hay una imagen, se guarda en el estado
    if (fileImg) {
      setImgProfile(imgFormData);
      formData = {
        ...formData,
        tag_names: selectedTags,
      };
      setDataForm(formData);
    } else {
      formData = {
        ...formData,
        tag_names: selectedTags,
      };
      if (
        userLoggedIn?.username === creatorRedux?.agency?.username &&
        loggedInType === "agency" &&
        userType === "creator"
      ) {
        editUserByAgency({
          creatorId: creatorRedux?.id,
          data: formData,
        });
      } else {
        editUser({
          type: userType,
          data: formData,
        });
      }
    }
  };
  useEffect(() => {
    if (isProfileImgError) {
      setErrorImg(true);
    }
  }, [isProfileImgError]);

  useEffect(() => {
    if (isProfileImgSuccess) {
      if (
        userLoggedIn?.username === creatorRedux?.agency?.username &&
        loggedInType === "agency" &&
        userType === "creator"
      ) {
        editUserByAgency({
          creatorId: creatorRedux?.id,
          data: { ...dataForm, profile_img_url: profileImgData.data.url },
        });
      } else {
        editUser({
          type: userType,
          data: { ...dataForm, profile_img_url: profileImgData.data.url },
        });
      }
    }
  }, [
    isProfileImgSuccess,
    dataForm,
    profileImgData,
    editUser,
    userType,
    creatorRedux,
    editUserByAgency,
    loggedInType,
    userLoggedIn,
  ]);

  const getUserData = useCallback(() => {
    if (!editUserData && !editUserByAgencyData) return null;

    const relevantData =
      editUserData?.data?.[userType] || editUserByAgencyData?.data?.[userType];
    if (!relevantData) return null;

    const userDataMap = {
      creator: {
        data: relevantData,
        redux: creatorRedux,
        updateAction: updateUserCreatorField,
        fields: ["username", "profile_img_url", "description", "tags"],
      },
      agency: {
        data: relevantData,
        redux: agencyRedux,
        updateAction: updateUserAgencyField,
        fields: ["username", "profile_img_url", "description"],
      },
      advertiser: {
        data: relevantData,
        redux: advertiserRedux,
        updateAction: updateUserAdvertiserField,
        fields: ["username", "profile_img_url", "description", "tags"],
      },
    };

    return userDataMap[userType] || null;
  }, [
    editUserData,
    editUserByAgencyData,
    userType,
    creatorRedux,
    agencyRedux,
    advertiserRedux,
  ]);

  useEffect(() => {
    if (!isEditUserSuccess && !isEditUserByAgencySuccess) return;

    const userData = getUserData();
    if (!userData?.data) return;

    const { data, redux, updateAction, fields } = userData;

    // Actualizar campos en Redux
    fields.forEach((field) => {
      dispatch(updateAction({ field, value: data[field] }));
    });

    // Navegar si el nombre de usuario ha cambiado
    if (redux?.username !== data.username) {
      if (
        userLoggedIn?.type.toLowerCase() === userType &&
        userLoggedIn?.username === redux?.username
      ) {
        dispatch(
          updateUserLoggedField({ field: "username", value: data.username })
        );
      }
      navigate(`/${userType}/${data.username}`);
    }
    setSelectedTags(null);

    onClose();
  }, [
    isEditUserSuccess,
    isEditUserByAgencySuccess,
    userType,
    creatorRedux,
    agencyRedux,
    advertiserRedux,
    userLoggedIn,
    dispatch,
    navigate,
    onClose,
    getUserData,
  ]);

  const values = useWatch({
    control,
  });

  const isFormUnchanged = () => {
    return (
      values.username ===
        (creatorRedux?.username ||
          agencyRedux?.username ||
          advertiserRedux?.username ||
          "") &&
      values.description ===
        (creatorRedux?.description ||
          agencyRedux?.description ||
          advertiserRedux?.description ||
          "") &&
      selectedTags.toString() === (creatorRedux?.tags || []).toString() &&
      !fileImg
    );
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  useEffect(() => {
    if (tagsData) {
      const tagsArray = tagsData?.data?.map((tag) => tag.name);
      const filteredTags = tagsArray.filter(
        (tag) => !selectedTags.includes(tag)
      );
      setTags(filteredTags);
    }
  }, [tagsData, selectedTags]);

  useEffect(() => {
    if (isEditUserError && editUserError) {
      if (editUserError.data.error === "Username already in use") {
        setErrorEditUsername(true);
      } else {
        dispatch(
          setModalErrorEditUser({
            modal: true,
            message: editUserError?.data?.error,
          })
        );
      }
    }
  }, [isEditUserError, editUserError, dispatch]);
  useEffect(() => {
    if (isEditUserByAgencyError && editUserByAgencyError) {
      if (editUserByAgencyError.data.error === "Username already in use") {
        setErrorEditUsername(true);
      } else {
        dispatch(
          setModalErrorEditUser({
            modal: true,
            message: editUserByAgencyError?.data?.error,
          })
        );
      }
    }
  }, [isEditUserByAgencyError, editUserByAgencyError, dispatch]);

  useEffect(() => {
    if (errorEditUsername) {
      setError("username", {
        type: "manual",
        message: t("profile.edit.username_error_already_used"),
      });
    }
  }, [errorEditUsername, setError, t]);

  useEffect(() => {
    if (errorEditUsername) {
      setErrorEditUsername(false);
    }
  }, [errorEditUsername]);
  useEffect(() => {
    if (editUserByAgencyError) {
      setError("username", {
        type: "manual",
        message: t("profile.edit.username_error_already_used"),
      });
    }
  }, [editUserByAgencyError, setError, t]);

  useEffect(() => {
    if (editUserByAgencyError) {
      setErrorEditUsername(false);
    }
  }, [editUserByAgencyError]);

  return (
    <div className="overlayEdit">
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div
          className={`editProfileContainer ${
            userType === "creator"
              ? "mediaContainerCreator"
              : "mediaContainer mediaWidth"
          }`}
        >
          <div className="editProfileForm">
            <p>{t("profile.edit.title")}</p>
            <div className="editProfileImgContainer">
              <div
                className={`editProfileImg ${
                  userType === "creator" ? "radiusImgCreator" : "radiusImg"
                }`}
                onClick={() =>
                  document.getElementById("profileImageInput").click()
                }
              >
                <img
                  className={`avatarImg ${
                    userType === "creator" ? "radiusImgCreator" : "radiusImg"
                  }`}
                  src={profileImage}
                  alt="avatar"
                  onError={() => {
                    setProfileImage(
                      userType === "creator"
                        ? creatorProfileImg
                        : userType === "agency"
                        ? agencyProfileImg
                        : advertiserProfileImg
                    );
                  }}
                />
                <img
                  className={`iconEdit ${
                    userType === "creator" ? "radiusImgCreator" : "radiusImg"
                  }`}
                  src={edit}
                  alt="edit"
                />
              </div>
              {errorImg && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {t(
                    "onboarding.personal_information.profile_img_url.error_format"
                  )}
                </span>
              )}
              <input
                id="profileImageInput"
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            <div className="editProfileFormUsername">
              <label>
                {t("onboarding.personal_information.username.label_creator")}
              </label>
              <div className="formUsernameInputContainer">
                <img src={person} alt="iconUN" />
                <input
                  {...register("username", {
                    required: t(
                      "onboarding.personal_information.username.required_error"
                    ),
                    pattern: {
                      value: /^[a-zA-Z0-9\s]+$/,
                      message: t(
                        "onboarding.personal_information.username.pattern_error"
                      ),
                    },
                    minLength: {
                      value: 3,
                      message: t("landing.sign_up.creator.minlength_error"),
                    },
                    maxLength: {
                      value: 35,
                      message: t(
                        "onboarding.personal_information.username.max_length_error"
                      ),
                    },
                  })}
                />
              </div>
              {errors.username && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {errors.username.message}
                </span>
              )}
            </div>
            <div className="editProfileFormDescription">
              <div className="editProfileFormDescriptionTitle">
                <label>
                  {t("onboarding.personal_information.description.label")}
                </label>
                <span>
                  {t("onboarding.personal_information.description.desc")}
                </span>
              </div>
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: {
                    value: 300,
                    message: t(
                      "onboarding.personal_information.description.max_length_error"
                    ),
                  },
                }}
                render={({ field }) => (
                  <textarea
                    className="editProfileTextarea"
                    {...field}
                  ></textarea>
                )}
              />
              {errors.description && (
                <span className="formSpamError">
                  <img src={warning} alt="warning" height={16} width={16} />
                  {errors.description.message}
                </span>
              )}
            </div>
            {userType === "creator" && (
              <div className="editProfileFormTags">
                <div className="editProfileFormTagsContainer">
                  <div className="editProfileFormDescriptionTitle">
                    <label>{t("profile.create.content_you_make")}</label>
                    <span>{t("onboarding.content.tags.max_length")}</span>
                  </div>
                  <div className="tagsSelectedContainer">
                    {selectedTags.map((tag, index) => (
                      <div className="tag" key={index}>
                        <span>{t(`onboarding.content.tag_list.${tag}`)}</span>
                        <img
                          src={close}
                          alt="close"
                          onClick={() => handleSelectedTagClick(tag)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="editProfileFormTagsMapping">
                  {tags.map((tag, index) => (
                    <p
                      className="tag"
                      key={index}
                      onClick={() => handleTagClick(tag)}
                    >
                      {t(`onboarding.content.tag_list.${tag}`)}
                    </p>
                  ))}
                </div>
                {selectedTags.length > 5 ? (
                  <span className="formSpamError">
                    <img src={warning} alt="warning" height={16} width={16} />
                    {t("onboarding.content.tags.max_length_error")}
                  </span>
                ) : null}
              </div>
            )}
          </div>
          <div className="editProfileButtons">
            <button
              type="submit"
              className={`${
                selectedTags.length > 5 ||
                errors.username ||
                errors.description ||
                isFormUnchanged()
                  ? "buttonDisabled"
                  : "buttonSave"
              }`}
              disabled={
                selectedTags.length > 5 ||
                errors.username ||
                errors.description ||
                isFormUnchanged()
              }
            >
              {t("profile.create.btn_save")}
            </button>
            <button type="button" className="buttonClose" onClick={onClose}>
              {t("general.btn_close")}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
