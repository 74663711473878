import { useEffect, useRef, useState } from "react";

import Chat from "./Chat";
import iconArrow from "../../../assets/icons/IconArrow.svg";
import "./ChatConversations.styles.css";
import { socket } from "../../../utils/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  updateConversationField,
  updateSelectedConversation,
  updateSelectedConversationChat,
} from "../../../redux/api/conversations/conversationsSlice";
import { useTranslation } from "react-i18next";

const ChatConversations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLoggedIn = useSelector((state) => state.login.user);
  const allMessages = useSelector(
    (state) => state.conversations.selectedConversationChat
  );
  const conversation = useSelector(
    (state) => state.conversations.selectedConversation
  );

  const [message, setMessage] = useState("");
  const socketRef = useRef(socket);

  const sendMessage = (e) => {
    e.preventDefault();

    if (!message.trim()) return;

    socketRef.current.emit("sendMessage", {
      conversation_id: allMessages[0].conversation_id,
      sender_user_id: userLoggedIn.id,
      receiver_user_id: conversation.advertiser
        ? conversation.advertiser.user_id
        : conversation.creator
        ? conversation.creator.user_id
        : conversation.agency.user_id,
      content: message,
      type: "REGULAR",
    });
    const messageObj = {
      conversation_id: allMessages[0].conversation_id,
      sender_user_id: userLoggedIn.id,
      receiver_user_id: conversation.advertiser
        ? conversation.advertiser.user_id
        : conversation.creator
        ? conversation.creator.user_id
        : conversation.agency.user_id,
      content: message,
      type: "REGULAR",
    };
    dispatch(updateSelectedConversationChat(messageObj));
    setMessage("");
  };

  useEffect(() => {
    const socketCurrent = socketRef.current;
    socketCurrent.on(`message_new`, (message) => {
      dispatch(updateSelectedConversationChat(message));
    });
    socketCurrent.on(`conversation_status_changed`, (message) => {
      dispatch(
        updateConversationField({
          id: message.conversation_id,
          field: "status",
          value: message.status,
        })
      );
      dispatch(
        updateSelectedConversation({
          field: "status",
          value: message.status,
        })
      );
    });

    return () => {
      socketCurrent.off(`message_new`);
      socketCurrent.off(`conversation_status_changed`);
    };
  }, [userLoggedIn.id, dispatch]);

  return (
    <div className="chat__container">
      <div className="chat__messagesContainer">
        <Chat />
      </div>
      <form onSubmit={sendMessage}>
        <div
          className={`${
            conversation?.status === "ACTIVE"
              ? "chat__inputContainer"
              : "chat__inputContainer chat__disabled"
          } `}
        >
          {conversation && (
            <>
              <input
                value={message}
                type="text"
                onChange={(e) => setMessage(e.target.value)}
                placeholder={t("chat.messages.input_placeholder")}
                disabled={conversation?.status === "ACTIVE" ? false : true}
              />
              <button
                type="submit"
                disabled={conversation?.status === "ACTIVE" ? false : true}
              >
                <p>{t("profile.init_talk.btn_send")}</p>
                <img
                  className="svg"
                  src={iconArrow}
                  alt="arrow"
                  width={20}
                  height={20}
                />
              </button>
            </>
          )}
        </div>
      </form>
    </div>
  );
};

export default ChatConversations;
