import { useState, useEffect } from "react";
import "./DateSelectorAnalytics.css";

const DateSelectorAnalytics = ({ options, onSelect, disabledOptions = [] }) => {
  // Encuentra el primer índice que no esté en disabledOptions
  const getInitialIndex = () =>
    options.findIndex((_, index) => !disabledOptions.includes(index));

  const [activeIndex, setActiveIndex] = useState(getInitialIndex());

  useEffect(() => {
    // Recalcula el índice activo cuando cambian las opciones o los deshabilitados
    const newIndex = getInitialIndex();
    setActiveIndex(newIndex);
    if (onSelect && newIndex !== -1) {
      onSelect(options[newIndex]);
    }
  }, [options, disabledOptions, onSelect, getInitialIndex]);

  const handleClick = (index) => {
    if (!disabledOptions.includes(index)) {
      setActiveIndex(index);
      if (onSelect) {
        onSelect(options[index]);
      }
    }
  };

  return (
    <div className="analyticsNetworksHeader__timeSelector">
      {options.map((option, index) => (
        <p
          key={index}
          className={
            index === activeIndex
              ? "analyticsNetworksHeader__timeSelectorActive"
              : "analyticsNetworksHeader__timeSelectorDisabled"
          }
          onClick={() => handleClick(index)}
          style={{
            cursor: disabledOptions.includes(index) ? "not-allowed" : "pointer",
            opacity: disabledOptions.includes(index) ? 0.5 : 1,
          }}
        >
          {option}
        </p>
      ))}
    </div>
  );
};

export default DateSelectorAnalytics;
