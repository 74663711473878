import React from "react";
import "./ChartTooltipContent.css";

const ChartTooltipContent = ({
  active,
  payload,
  isBubbleChart = false,
  className = "",
  indicator = "dot",
  hideLabel = false,
  hideIndicator = false,
  label,
  labelFormatter,
  formatter,
  color,
  bubbleColor,
  nameKey,
  bubbleMetric,
  percentage
}) => {
  if (!active || !payload?.length) {
    return null;
  }

  const data = payload[0]?.payload;
  if (!data) return null;

  // 🔹 Tooltip para el BubbleChart
  if (isBubbleChart) {
    return (
      <div className={`chart-tooltip ${className}`}>
        <div className="chart-tooltip-label">
          {data.yFormatted} {String(data.x).padStart(2, "0")}:00hs
        </div>

        <div className="chart-tooltip-content">
          <div className="chart-tooltip-item">
            <div
              className="chart-tooltip-indicator"
              style={{
                backgroundColor: bubbleColor,
              }}
            />
            <span className="chart-tooltip-name">{bubbleMetric}</span>
          </div>
          <span className="chart-tooltip-value">{data.z}</span>
        </div>
      </div>
    );
  }

  // 🔹 Tooltip estándar para otros gráficos
  return (
    <div className={`chart-tooltip ${className}`}>
      {!hideLabel && label && (
        <div className="chart-tooltip-label">
          {labelFormatter ? labelFormatter(label, payload) : label}
        </div>
      )}
      <div className="chart-tooltip-items">
        {payload.map((item, index) => (
          <div key={item.dataKey || index} className="chart-tooltip-item">
            {!hideIndicator && (
              <div
                className={`chart-tooltip-indicator ${
                  indicator === "line"
                    ? "line"
                    : indicator === "dashed"
                    ? "dashed"
                    : ""
                }`}
                style={{
                  backgroundColor:
                    indicator === "dot"
                      ? color || item.color || item.payload.fill
                      : "",
                  borderColor:
                    indicator === "dashed"
                      ? color || item.color || item.payload.fill
                      : "",
                }}
              />
            )}
            <div className="chart-tooltip-content">
              <span className="chart-tooltip-name">
                {item.name || nameKey || "N/A"}
              </span>
              <span className="chart-tooltip-value">
                {formatter
                  ? formatter(item.value, item.name, item, index, item.payload)
                  : item.value} {percentage && "%"}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChartTooltipContent;
