import PieBasicChart from "./ChartPie";
import info from "../../../../assets/icons/IconInfo.svg";
import youtube from "../../../../assets/icons/IconYoutube.svg";
import tiktok from "../../../../assets/icons/IconTiktok.svg";
import instagram from "../../../../assets/icons/IconInstagramLine.svg";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardInfo,
  CardTitle,
} from "../cardCharts/CardCharts";
import Tooltip from "../../Tooltip/Tooltip";
import EmptyState from "../emptyState/EmptyState";

const PieChartCard = ({
  title,
  data,
  dataKey,
  cx,
  cy,
  fill,
  innerRadiusFactor,
  cornerRadius,
  tooltipDescription,
  network,
  percentage,
}) => {
  const isDataEmpty = !data || data.length === 0;
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <p style={{ width: "max-content" }}>{title}</p>
        </CardTitle>
        <CardInfo>
          <Tooltip content={tooltipDescription} side="bottom">
            <img src={info} alt="info" />
          </Tooltip>
          <img
            src={
              network === "YOUTUBE"
                ? youtube
                : network === "TIKTOK"
                ? tiktok
                : instagram
            }
            alt="network"
          />
        </CardInfo>
      </CardHeader>
      <CardContent>
        {isDataEmpty ? (
          <EmptyState />
        ) : (
          <div style={{ width: "100%", height: 200, display: "flex" }}>
            <PieBasicChart
              data={data}
              dataKey={dataKey}
              cx={cx}
              cy={cy}
              fill={fill}
              innerRadiusFactor={innerRadiusFactor}
              cornerRadius={cornerRadius}
              percentage={percentage}
            />
          </div>
        )}
      </CardContent>
      {!isDataEmpty && (
        <CardFooter>
          {data
            .filter((item) => item.value > 0)
            .map((item, index) => (
              <div key={index}>
                <div
                  style={{
                    backgroundColor: item.fill || fill,
                    width: 12,
                    height: 12,
                    marginRight: 8,
                    borderRadius: 4,
                  }}
                />
                <p>{item.name}</p>
              </div>
            ))}
        </CardFooter>
      )}
    </Card>
  );
};

export default PieChartCard;
