import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import ChartTooltipContent from "../tooltip/ChartTooltipContent";

const daysMapping = {
  Lun: 1,
  Mar: 2,
  Mié: 3,
  Jue: 4,
  Vie: 5,
  Sáb: 6,
  Dom: 7,
};

const BubbleChart = ({ data, bubble, xAxisLabel, yAxisLabel, zAxisLabel }) => {
  // Convertir los días de la semana a números para el eje Y
  const formattedData =
    data?.map((d) => ({
      ...d,
      y: daysMapping[d.y], // Convertir día a número
      yFormatted: d.y, // Mantener el nombre del día para el tooltip
    })) || [];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ScatterChart data={formattedData} margin={{ top: 20, bottom: 20 }}>
        <CartesianGrid vertical={false} />

        <XAxis
          type="number"
          dataKey="x"
          domain={[0, 24]}
          ticks={Array.from({ length: 24 }, (_, index) => index)}
          name={xAxisLabel}
          tickFormatter={(hour) => `${hour}:00`}
          tickMargin={20}
          tickLine={false}
          axisLine={false}
        />

        <YAxis
          type="number"
          dataKey="y"
          domain={[1, 7]}
          ticks={[1, 2, 3, 4, 5, 6, 7]}
          name={yAxisLabel}
          tickFormatter={(day) =>
            Object.keys(daysMapping).find((key) => daysMapping[key] === day)
          }
          tickMargin={16}
          tickLine={false}
          axisLine={false}
        />

        <ZAxis type="number" dataKey="z" range={[0, 400]} name={zAxisLabel} />

        <Tooltip
          content={
            <ChartTooltipContent isBubbleChart bubbleMetric={zAxisLabel} bubbleColor={bubble[0].fill} />
          }
        />
        {bubble.map((item) => (
          <Scatter
            key={item.dataKey}
            name={item.dataKey}
            dataKey={item.dataKey}
            fill={item.fill}
          />
        ))}
      </ScatterChart>
    </ResponsiveContainer>
  );
};

export default BubbleChart;
