import Error from "../../components/UI/Error/Error";

const Error404 = () => {
  return (
    <>
      <Error />
    </>
  );
};

export default Error404;
