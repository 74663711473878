import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  analyticsNetSelected: {},
  platformSelected: null,
  idNetSelected: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalyticsNetSelected: (state, action) => {
      state.analyticsNetSelected = action.payload;
    },
    setPlatformSelected: (state, action) => {
      state.platformSelected = action.payload;
    },
    setIdNetSelected: (state, action) => {
      state.idNetSelected = action.payload;
    },
  },
});

export default analyticsSlice.reducer;
export const {
  setAnalyticsNetSelected,
  setPlatformSelected,
  setIdNetSelected,
} = analyticsSlice.actions;
