import React from "react";
import {
  CartesianGrid,
  LabelList,
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ChartTooltipContent from "../tooltip/ChartTooltipContent";

const BarBasicChart = ({
  data = [],
  bars = [],
  xAxisKey,
  margin = { top: 10, right: 10, left: 0, bottom: 0 },
  isHorizontal,
}) => {
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={margin}
        layout={isHorizontal ? "horizontal" : "vertical"}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {isHorizontal ? (
          <>
            <XAxis
              tickMargin={10}
              tickLine={false}
              axisLine={false}
              tickCount={10}
              dataKey={xAxisKey}
              tickFormatter={(value) =>
                xAxisKey === "week" ? value : value.slice(0, 3)
              }
              type="category"
            />
            <YAxis
              tickMargin={10}
              tickLine={false}
              axisLine={false}
              tickCount={10}
              type="number"
              allowDecimals={false}
            />
          </>
        ) : (
          <>
            <XAxis
              tickMargin={10}
              tickLine={false}
              axisLine={false}
              tickCount={10}
              type="number"
              allowDecimals={false}
            />
            <YAxis
              tickMargin={10}
              type="category"
              tickLine={false}
              axisLine={false}
              tickCount={10}
              dataKey={xAxisKey}
              tickFormatter={(value) =>
                xAxisKey === "week" ? value : value.slice(0, 3)
              }
            />
          </>
        )}

        <Tooltip content={<ChartTooltipContent />} />

        {bars.map((barConfig, index) => (
          <Bar
            key={index}
            type="monotone"
            dataKey={barConfig.dataKey}
            stroke={barConfig.stroke}
            fill={barConfig.fill}
            radius={barConfig.radius}
            barSize={barConfig.barSize}
          >
            {barConfig.labelPosition && (
              <LabelList position={barConfig.labelPosition} offset={10} />
            )}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarBasicChart;
