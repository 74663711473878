import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import audience from "../../../assets/icons/IconAudience.svg";

import AudienceYT from "./AnalyticsYT/AudienceYT";

import "./AnalyticsCharts.styles.css";
import AudienceTK from "./AnalyticsTK/AudienceTK";

const AnalyticsAudience = ({ analyticsAudience }) => {
  const { t } = useTranslation();
  const platform = useSelector((state) => state.analytics.platformSelected);

  return (
    <div className="analyticsChartsContainer">
      <div className="analyticsChartsTabs">
        <div className="analyticsChartsActiveTab">
          <img src={audience} alt="audience" />
          <p>{t("analytics.sections.audience")}</p>
        </div>
      </div>
      <div className="analyticsChartsContent">
        {platform === "YOUTUBE" && (
          <AudienceYT analyticsAudience={analyticsAudience} />
        )}
        {platform === "TIKTOK" && (
          <AudienceTK analyticsAudience={analyticsAudience} />
        )}
      </div>
    </div>
  );
};

export default AnalyticsAudience;
