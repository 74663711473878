import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

const AreaGradientChartFree = ({
  data = [],
  areas = [],
  xAxisKey,
}) => {
  return (
    <ResponsiveContainer>
      <AreaChart data={data} >
        <defs>
          {areas.map((area, index) => (
            <linearGradient
              key={index}
              id={`color${area.dataKey}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={area.color} stopOpacity={1} />
              <stop offset="100%" stopColor={area.color} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <XAxis
          tickMargin={8}
          tickLine={false}
          axisLine={false}
          tickCount={10}
          dataKey={xAxisKey}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <YAxis tickMargin={16} tickLine={false} axisLine={false} />
        <CartesianGrid strokeDasharray="0 0" />
        {areas.map((area, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={area.dataKey}
            stroke={area.color}
            fillOpacity={1}
            fill={`url(#color${area.dataKey})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaGradientChartFree;
