import info from "../../../../assets/icons/IconInfo.svg";
import youtube from "../../../../assets/icons/IconYoutube.svg";
import tiktok from "../../../../assets/icons/IconTiktok.svg";
import instagram from "../../../../assets/icons/IconInstagramLine.svg";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardInfo,
  CardTitle,
} from "../cardCharts/CardCharts";
import Tooltip from "../../Tooltip/Tooltip";
import EmptyState from "../emptyState/EmptyState";
import ChartScatter from "./ChartScatter";

const ChartCardScatter = ({
  title,
  data,
  bubble,
  tooltipDescription,
  xAxisLabel,
  yAxisLabel,
  zAxisLabel,
  network,
}) => {
  const isDataEmpty = !data || data.length === 0;

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <p style={{ width: "max-content" }}>{title}</p>
        </CardTitle>
        <CardInfo>
          <Tooltip content={tooltipDescription} side="bottom">
            <img src={info} alt="info" />
          </Tooltip>
          <img
            src={
              network === "YOUTUBE"
                ? youtube
                : network === "TIKTOK"
                ? tiktok
                : instagram
            }
            alt="network"
          />
        </CardInfo>
      </CardHeader>
      <CardContent>
        {isDataEmpty ? (
          <EmptyState />
        ) : (
          <div style={{ width: "100%", height: 500, display: "flex" }}>
            <ChartScatter
              data={data}
              bubble={bubble}
              xAxisLabel={xAxisLabel}
              yAxisLabel={yAxisLabel}
              zAxisLabel={zAxisLabel}
            />
          </div>
        )}
      </CardContent>
      {!isDataEmpty && (
        <CardFooter>
          {bubble.map((area) => (
            <div key={area.dataKey}>
              <div style={{ backgroundColor: area.fill }} />
              <p>{area.dataKey}</p>
            </div>
          ))}
        </CardFooter>
      )}
    </Card>
  );
};

export default ChartCardScatter;
