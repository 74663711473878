import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PieChartCard from "../../../UI/Charts/pie/ChartCardPie";
import ChartCardScatter from "../../../UI/Charts/scatter/ChartCardScatter";

const AudienceTK = ({ analyticsAudience }) => {
  const { t } = useTranslation();

  const analytics = analyticsAudience?.data?.analytics;
  const platform = useSelector((state) => state.analytics.platformSelected);

  // Colores fijos proporcionados
  const colors = ["#25F3ED", "#FD2C55", "#000000", "#404040", "#808080"];

  // Función para traducir valores del backend
  const translateValue = (dimension, value) => {
    return t(`analytics.tk.dimensions.${dimension}.${value}`, value); // Fallback al valor original si no hay traducción
  };

  // Función para ordenar datos por valor y asignar colores
  const assignColorsByValue = (data, valueKey) => {
    return data
      .slice() // Crear una copia para no mutar el array original
      .sort((a, b) => b[valueKey] - a[valueKey]) // Ordenar de mayor a menor por el valor
      .map((item, index) => ({
        ...item,
        fill: colors[index % colors.length], // Asignar colores en orden
      }));
  };

  // Extraer y transformar datos de la dimensión "gender"
  const genderData =
    analytics?.find((item) => item.dimension === "gender")?.data || [];
  const transformedGenderData = assignColorsByValue(
    genderData,
    "percentage"
  ).map((item) => ({
    name: translateValue("gender", item.gender), // Traducir género
    value: parseFloat(item?.percentage?.toFixed(1)),
    fill: item.fill,
  }));

  // Extraer y transformar datos de la dimensión "country"
  const countryData =
    analytics?.find((item) => item.dimension === "country")?.data || [];

  const transformedCountryData = assignColorsByValue(
    [...countryData] // Hacemos una copia superficial del arreglo
      .sort((a, b) => b.percentage - a.percentage) // Ordenamos por 'percentage' de mayor a menor
      .slice(0, 5), // Tomamos solo los primeros 5 elementos
    "percentage"
  ).map((item) => ({
    name: translateValue("country", item.country),
    value: parseFloat(item?.percentage?.toFixed(1)),
    fill: item.fill,
  }));

  // Extraer y transformar datos de la dimensión "ageGroup"
  const ageGroupData =
    analytics?.find((item) => item.dimension === "age")?.data || [];
  const transformedAgeGroupDataWithColors = assignColorsByValue(
    ageGroupData,
    "percentage"
  ).map((item) => ({
    name: item.age, // Mostrar rango de edad
    value: parseFloat(item?.percentage?.toFixed(1)),
    fill: item.fill,
  }));

  function getAverageAudienceActivity(analytics, t) {
    // Usamos t() para obtener los nombres de los días según el idioma actual
    const daysOfWeek = [
      t("analytics.tk.dimensions.average_audience_activity.days.0"), // Domingo
      t("analytics.tk.dimensions.average_audience_activity.days.1"), // Lunes
      t("analytics.tk.dimensions.average_audience_activity.days.2"), // Martes
      t("analytics.tk.dimensions.average_audience_activity.days.3"), // Miércoles
      t("analytics.tk.dimensions.average_audience_activity.days.4"), // Jueves
      t("analytics.tk.dimensions.average_audience_activity.days.5"), // Viernes
      t("analytics.tk.dimensions.average_audience_activity.days.6"), // Sábado
    ];

    const data = [];

    // Recorremos los días de la semana (0 = Dom, 1 = Lun, ..., 6 = Sáb)
    analytics[4]?.data?.forEach((dayData) => {
      const dayName = daysOfWeek[dayData.day_number]; // Usamos day_number para acceder al nombre del día

      // Recorremos las horas de actividad para cada día
      dayData?.average_audience_activity?.forEach((hourData) => {
        data.push({
          x: hourData.hour, // Hora (de 0 a 23)
          y: dayName, // Día en el nombre traducido
          z: hourData.count, // Valor 'z' (tamaño de la burbuja, en este caso 'count')
        });
      });
    });

    return data;
  }

  // Llamada a la función con los datos mockeados y la función t
  const averageAudienceActivity = getAverageAudienceActivity(analytics, t);

  const bubble = [
    { dataKey: t("analytics.tk.metrics.interactions"), fill: "#FD2C55" },
  ];
  return (
    <>
      {/* Gráficos de torta */}
      <div className="analyticsChartsContentPie">
        <PieChartCard
          title={t("analytics.tk.dimensions.gender.title")}
          data={transformedGenderData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadiusFactor={0}
          cornerRadius={4}
          tooltipDescription={t("analytics.tk.dimensions.gender.tooltip")}
          network={platform}
          percentage
        />
        <PieChartCard
          title={t("analytics.tk.dimensions.country.title")}
          data={transformedCountryData}
          dataKey="value"
          cx="50%"
          cy="50%"
          cornerRadius={4}
          innerRadiusFactor={0.6}
          tooltipDescription={t("analytics.tk.dimensions.country.tooltip")}
          network={platform}
          percentage
        />
        <PieChartCard
          title={t("analytics.tk.dimensions.age_group.title")}
          data={transformedAgeGroupDataWithColors}
          dataKey="value"
          cx="50%"
          cy="50%"
          cornerRadius={4}
          innerRadiusFactor={0.6}
          tooltipDescription={t("analytics.tk.dimensions.age_group.tooltip")}
          network={platform}
          percentage
        />
      </div>
      {/* Gráficos de Burbujas */}
      <div className="analyticsChartsContentBars">
        <ChartCardScatter
          data={averageAudienceActivity}
          title={t("analytics.tk.dimensions.average_audience_activity.title")}
          tooltipDescription={t(
            "analytics.tk.dimensions.average_audience_activity.tooltip"
          )}
          bubble={bubble}
          xAxisLabel="day"
          yAxisLabel="hour"
          zAxisLabel={t("analytics.tk.metrics.interactions")}
          network={platform}
        />
      </div>
    </>
  );
};

export default AudienceTK;
