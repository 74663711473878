import React, { useEffect } from "react";
import YTConnect from "../../../assets/icons/IconYoutubeConnect.svg";
import TKConnect from "../../../assets/icons/IconTiktokConnect.svg";
import IGConnect from "../../../assets/icons/IconInstagramConnect.svg";
import "./SignUpIntegration.styles.css";
import {
  useAddNetworkExternalMutation,
  useAddNetworkMutation,
} from "../../../redux/api/network/networkQuery";
import { PLATFORMS, TIKTOK_URL } from "../../../redux/constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalErrorNetwork,
  setModalSuccessNetwork,
} from "../../../redux/modalSlice";
import { useTranslation } from "react-i18next";

const SignUpIntegration = ({ UrlCC }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.login.user);

  //Funcion para crear el estado para la URL de autenticación de TikTok
  const creatorState = { creator_id: loginUserData?.creator_id };
  const agencyState = {
    network_connection_code: UrlCC,
  };
  const buildTikTokAuthUrl = (state) => {
    const stateParam = encodeURIComponent(JSON.stringify(state));

    return `${TIKTOK_URL}&state=${stateParam}`;
  };

  const [
    addNetwork,
    {
      data: networkData,
      isSuccess: isNetworkSuccess,
      isError: isNetworkError,
      error: networkError,
    },
  ] = useAddNetworkMutation();
  const [
    addNetworkExternal,
    {
      data: networkExternalData,
      isSuccess: isNetworkExternalSuccess,
      isError: isNetworkExternalError,
      error: networkExternalError,
    },
  ] = useAddNetworkExternalMutation();

  useEffect(() => {
    if (isNetworkSuccess && networkData.ok) {
      navigate(
        `/${loginUserData.type.toLowerCase()}/${loginUserData.username}`
      );
    }
  }, [isNetworkSuccess, networkData, loginUserData, navigate]);

  useEffect(() => {
    if (isNetworkExternalSuccess && networkExternalData.ok) {
      dispatch(setModalSuccessNetwork(true));
    }
  }, [networkExternalData, isNetworkExternalSuccess, dispatch]);

  useEffect(() => {
    if (isNetworkError || isNetworkExternalError) {
      dispatch(
        setModalErrorNetwork({
          modal: true,
          message:
            networkError?.data.error || networkExternalError?.data?.error,
        })
      );
    }
  }, [
    isNetworkError,
    dispatch,
    networkError,
    isNetworkExternalError,
    networkExternalError,
  ]);

  const handleYTConnect = async () => {
    await window.initTokenClient((response) => {
      if (UrlCC) {
        const payload = {
          authorization_code: response.code,
          platform: PLATFORMS.Youtube,
          network_connection_code: UrlCC,
        };
        addNetworkExternal(payload);
      } else {
        const payload = {
          authorization_code: response.code,
          platform: PLATFORMS.Youtube,
        };
        addNetwork(payload);
      }
    });
    window.getToken();
  };

  const handleTkConnect = () => {
    if (UrlCC) {
      const authUrl = buildTikTokAuthUrl(agencyState);
      window.location.href = authUrl;
    } else {
      const authUrl = buildTikTokAuthUrl(creatorState);
      window.location.href = authUrl;
    }
  };
  const handleSkip = () => {
    navigate(`/${loginUserData.type.toLowerCase()}/${loginUserData.username}`);
  };
  return (
    <div className="integrationContainer">
      <div className="integrationTitleContainer">
        {UrlCC ? (
          <label>{t("onboarding.external_integration.title")}</label>
        ) : (
          <label>{t("onboarding.youtube_integration.title")}</label>
        )}
        <div className="integrationParagraphContainer">
          {UrlCC ? (
            <>
              <label>{t("onboarding.external_integration.paragraph_1")}</label>
            </>
          ) : (
            <>
              <label>{t("onboarding.youtube_integration.paragraph_1")}</label>
              <label>{t("onboarding.youtube_integration.paragraph_2")}</label>
            </>
          )}
        </div>
      </div>
      <div className="integrationButtonContainer">
        <button
          className="integrationButton integrationButtonYT"
          onClick={handleYTConnect}
        >
          <img src={YTConnect} alt="YTConnect" />
          {t("onboarding.youtube_integration.button_connect")}
        </button>
        <button
          className="integrationButton integrationButtonTK"
          onClick={handleTkConnect}
        >
          <img src={TKConnect} alt="TKConnect" />
          {t("onboarding.tiktok_integration.button_connect")}
        </button>
        {/* <button
          className="integrationButton integrationButtonIG"
          onClick={handleTkConnect}
        >
          <img src={IGConnect} alt="IGConnect" />
          {t("onboarding.instagram_integration.button_connect")}
        </button> */}
        {!UrlCC && (
          <span className="skipButton" onClick={() => handleSkip()}>
            Omitir por ahora
          </span>
        )}
      </div>
    </div>
  );
};

export default SignUpIntegration;
