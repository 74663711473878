import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "./FinishChatModal.styles.css";
import { useEffect } from "react";
import {
  setModalFinishConversation,
  setSelectedConversationChat,
  updateConversationField,
  updateSelectedConversation,
} from "../../../../redux/api/conversations/conversationsSlice";
import {
  useGetConversationByIdQuery,
  useUpdateConversationByIdMutation,
} from "../../../../redux/api/conversations/conversationsQuery";

const FinishChatModal = ({ id, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    updateConversationById,
    { data: updateConversationData, isSuccess: isUpdateSuccess },
  ] = useUpdateConversationByIdMutation();

  const { data: conversationIdData, isSuccess: isConversationIdSuccess } =
    useGetConversationByIdQuery(id, {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });

  const handleFinish = () => {
    if (type === "ADVERTISER") {
      updateConversationById({ id, status: "FINISH_APPROVAL_PENDING" });
    } else {
      updateConversationById({ id, status: "FINISHED" });
    }
  };

  const handleClose = () => {
    dispatch(
      setModalFinishConversation({ modal: false, conversationId: null })
    );
  };

  useEffect(() => {
    if (isUpdateSuccess && updateConversationData) {
      dispatch(
        updateConversationField({
          id,
          field: "status",
          value: updateConversationData.conversation.status,
        })
      );
      dispatch(
        updateSelectedConversation({
          field: "status",
          value: updateConversationData.conversation.status,
        })
      );
      dispatch(
        setModalFinishConversation({ modal: false, conversationId: null })
      );
    }
  }, [isUpdateSuccess, dispatch, updateConversationData, id]);

  useEffect(() => {
    if (isConversationIdSuccess && conversationIdData) {
      dispatch(setSelectedConversationChat(conversationIdData.messages));
    }
  }, [isConversationIdSuccess, conversationIdData, dispatch]);

  return (
    <div className="modalFinishChat">
      <div className="modalFinishChat__container">
        <p className="modalFinishChat__title">
          {t("chat.messages.btn_finish")}
        </p>
        <p className="modalFinishChat__subtitle">
          {t("chat.messages.confirm_finish_text")}
        </p>
        <div className="modalFinishChat__buttons">
          <button onClick={handleFinish}>
            {t("chat.messages.btn_yes_finish")}
          </button>
          <button onClick={handleClose}>{t("general.btn_cancel")}</button>
        </div>
      </div>
    </div>
  );
};

export default FinishChatModal;
