import HeaderConversations from "./HeaderConversations/HeaderConversations";
import StatusConversations from "./StatusConversations/StatusConversations";
import ChatConversations from "./ChatConversations/ChatConversations";

import "./Conversations.styles.css";
import { useGetConversationsQuery } from "../../redux/api/conversations/conversationsQuery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllConversations } from "../../redux/api/conversations/conversationsSlice";
import FinishChatModal from "../UI/ModalMessages/FinishChatModal/FinishChatModal";
import NewChatModal from "../UI/ModalMessages/NewChatModal/NewChatModal";

const Conversations = () => {
  const dispatch = useDispatch();
  const modalFinishChat = useSelector(
    (state) => state.conversations.modalFinishConversation
  );
  const modalNewChat = useSelector(
    (state) => state.conversations.modalConversation
  );

  const { data: conversationsData } = useGetConversationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: false,
    keepUnusedDataFor: 0,
  });

  useEffect(() => {
    if (conversationsData) {
      dispatch(setAllConversations(conversationsData.conversations));
    }
  }, [conversationsData, dispatch]);

  return (
    <div className="conversations__container">
      <StatusConversations />
      <div className="conversations__chatContainer">
        <HeaderConversations />
        <ChatConversations />
      </div>
      {modalFinishChat.modal && (
        <FinishChatModal
          id={modalFinishChat.conversationId}
          type={modalFinishChat.type}
        />
      )}
      {modalNewChat.modal && (
        <NewChatModal
          userId={modalNewChat.userId}
          conversationId={modalNewChat.conversationId}
        />
      )}
    </div>
  );
};

export default Conversations;
