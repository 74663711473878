import React, { useEffect, useState } from "react";
import iconPlus from "../../assets/icons/IconPlus.svg";
import "./Opportunities.styles.css";
import Kanban from "./Kanban/Kanban";
import { useDispatch, useSelector } from "react-redux";
import CreateOpp from "./CreateOpp/CreateOpp";
import { setModalCreateOpp } from "../../redux/api/opportunities/opportunitiesSlice";
import { useTranslation } from "react-i18next";
import DeleteOppModal from "../UI/ModalMessages/DeleteOppModal/DeleteOppModal";
import {
  setModalErrorCreateOpp,
  setModalErrorEditOpp,
} from "../../redux/modalSlice";
import InformationModal from "../UI/ModalMessages/InformationModal/InformationModal";

const Opportunities = ({ isLoading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const createOpp = useSelector((state) => state.opportunities.modalCreateOpp);
  const modalDelete = useSelector((state) => state.modal.modalDeleteOpp);
  const modalErrorCreate = useSelector(
    (state) => state.modal.modalErrorCreateOpp
  );
  const modalErrorEdit = useSelector((state) => state.modal.modalErrorEditOpp);
  const [modalData, setModalData] = useState({});

  const handleCreateOpp = () => {
    dispatch(setModalCreateOpp(true));
  };
  const handleCloseCreateOpp = () => {
    dispatch(setModalCreateOpp(false));
  };
  const handleClose = () => {
    dispatch(setModalErrorCreateOpp({ modal: false, message: null }));
    dispatch(setModalErrorEditOpp({ modal: false, message: null }));
    setModalData({});
  };

  useEffect(() => {
    if (modalErrorCreate.modal && modalErrorCreate.message) {
      setModalData({
        icon: "warning",
        title: t("opportunities.modal.error_create.title"),
        text: t("opportunities.modal.error_create.subtitle"),
        textButtonClose: t("opportunities.modal.error_create.btn_close"),
      });
    }
  }, [modalErrorCreate.message, modalErrorCreate.modal, t]);
  useEffect(() => {
    if (modalErrorEdit.modal && modalErrorEdit.message) {
      setModalData({
        icon: "warning",
        title: t("opportunities.modal.error_edit.title"),
        text: t("opportunities.modal.error_edit.subtitle"),
        textButtonClose: t("opportunities.modal.error_edit.btn_close"),
      });
    }
  }, [modalErrorEdit.message, modalErrorEdit.modal, t]);

  return (
    <div className="opportunities">
      <div className="opportunitiesHeader">
        <p>{t("opportunities.title")}</p>
        <button onClick={handleCreateOpp}>
          <img src={iconPlus} alt="add_opp" />
          <p>{t("opportunities.empty_btn")}</p>
        </button>
      </div>
      <Kanban isLoading={isLoading} />
      {createOpp && <CreateOpp onClose={handleCloseCreateOpp} />}
      {modalDelete.modal && <DeleteOppModal id={modalDelete.id} />}
      {modalErrorCreate.modal && (
        <InformationModal
          data={modalData}
          onClose={handleClose}
          onAccept={null}
        />
      )}
      {modalErrorEdit.modal && (
        <InformationModal
          data={modalData}
          onClose={handleClose}
          onAccept={null}
        />
      )}
    </div>
  );
};

export default Opportunities;
