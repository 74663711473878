import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setUserRegister: (state, action) => {
      state.data = action.payload;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
  },
});

export default signupSlice.reducer;
export const { setUserRegister, setType } = signupSlice.actions;
