import link_image from "../../../../assets/images/undraw_share_link.svg";
import close from "../../../../assets/icons/IconClose.svg";
import link from "../../../../assets/icons/IconLink.svg";
import copy from "../../../../assets/icons/IconCopy.svg";
import ok from "../../../../assets/icons/IconOk.svg";
import "./NetworkModal.styles.css";
import { useRef, useState } from "react";
import { useGetURLAddNetworkQuery } from "../../../../redux/api/user/userQuery";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";

const NetworkModal = ({ platform, onClose }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const inputRef = useRef(null);
  const creatorRedux = useSelector((state) => state.userCreator.creator);

  const {
    data: urlData,
    error,
    isLoading,
  } = useGetURLAddNetworkQuery(creatorRedux?.username);

  const handleCopy = () => {
    if (inputRef.current) {
      navigator.clipboard
        .writeText(inputRef.current.value)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 2000);
        })
        .catch((err) => {
          console.error("Error al copiar: ", err);
        });
    }
  };

  if (error) {
    return <div>Error al cargar los datos</div>;
  }

  return (
    <div className="overlayNetwork">
      {isLoading && <Loader />}
      <div className="modalNetwork">
        <div className="closeModal">
          <img onClick={onClose} src={close} alt="Cerrar" />
        </div>
        <div className="modalNetworkContainer">
          <div className="networkImageContainer">
            <img src={link_image} alt="Link" />
          </div>
          <div className="networkTextContainer">
            <p>{t("profile.network_modal.title")}</p>
            <p>{t("profile.network_modal.text")}</p>
          </div>
          <div className="networkLinkContainer">
            <div className="networkLink">
              <img src={link} alt="Link" />
              <input
                ref={inputRef}
                defaultValue={urlData?.data?.url}
                disabled
              />
            </div>
            {isCopied ? (
              <div className="buttonCopy">
                <p>{t("profile.network_modal.btn_copied")}</p>
                <img src={ok} alt="Copiado" />
              </div>
            ) : (
              <button className="buttonCopy" onClick={handleCopy}>
                <p>{t("profile.network_modal.btn_copy")}</p>
                <img src={copy} alt="Copiar" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkModal;
