import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import youtube from "../../../assets/icons/IconYoutube.svg";
import tiktok from "../../../assets/icons/IconTiktok.svg";
import instagram from "../../../assets/icons/IconInstagram.svg";
import avatar from "../../../assets/images/avatarDemo.png";
import arrow from "../../../assets/icons/IconArrowGrey.svg";
import barChart from "../../../assets/icons/IconBarChart.svg";

import {
  setIdNetSelected,
  setPlatformSelected,
} from "../../../redux/api/analytics/analyticsSlice";

import "./AnalyticsNetworks.styles.css";
import DateSelectorAnalytics from "../../UI/DateSelector/DateSelectorAnalytics";

const AnalyticsNetworks = ({ creatorData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeAccount, setActiveAccount] = useState(creatorData?.networks[0]);

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (creatorData?.networks?.[0]?.platform) {
      setActiveTab(creatorData.networks[0].platform);
    }
  }, [creatorData]);

  useEffect(() => {
    dispatch(setPlatformSelected(activeTab));
  }, [activeTab, dispatch]);

  // Obtiene el ícono de la plataforma
  const getIcon = (platform) => {
    switch (platform) {
      case "YOUTUBE":
        return youtube;
      case "TIKTOK":
        return tiktok;
      case "INSTAGRAM":
        return instagram;
      default:
        return youtube;
    }
  };

  // Ordena los tabs dinámicamente para que la primera red del usuario esté primero
  const formatTitle = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const networks = useMemo(() => {
    if (!creatorData?.networks?.length) return [];

    // Genera un array con las plataformas que el usuario tiene integradas
    const integratedNetworks = creatorData.networks.map((network) => ({
      platform: network.platform,
      icon: getIcon(network.platform),
      title: formatTitle(network.platform),
    }));

    // Asegúrate de que la primera plataforma del usuario esté al principio
    const firstNetwork = creatorData.networks[0].platform;

    // Filtra las redes integradas, asegurándote de que solo las plataformas del usuario sean visibles
    const orderedNetworks = [
      {
        platform: firstNetwork,
        icon: getIcon(firstNetwork),
        title: formatTitle(firstNetwork),
      },
      ...[
        { platform: "YOUTUBE", icon: youtube, title: "Youtube" },
        { platform: "TIKTOK", icon: tiktok, title: "Tiktok" },
        { platform: "INSTAGRAM", icon: instagram, title: "Instagram" },
      ]
        .filter((net) => net.platform !== firstNetwork)
        .filter((net) =>
          integratedNetworks.some(
            (network) => network.platform === net.platform
          )
        ),
    ];

    return orderedNetworks;
  }, [creatorData?.networks]);

  const handleTabClick = (networkName) => {
    setActiveTab(networkName);
    dispatch(setPlatformSelected(networkName));
  };

  // Filtra las redes según el tab activo
  const filteredNetworks = useMemo(() => {
    return (
      creatorData?.networks?.filter(
        (network) => network.platform === activeTab
      ) || []
    );
  }, [creatorData?.networks, activeTab]);

  useEffect(() => {
    if (filteredNetworks.length > 0) {
      setActiveAccount(filteredNetworks[0]);
      dispatch(setIdNetSelected(filteredNetworks[0].id));
    } else {
      setActiveAccount(null);
      dispatch(setIdNetSelected(null));
    }
  }, [filteredNetworks, dispatch]);

  const handleSelectAccount = (account) => {
    setActiveAccount(account);
    dispatch(setIdNetSelected(account.id));
  };

  const handleNavigateProfile = () => {
    navigate(`/creator/${creatorData?.username}`);
  };

  const handleDateSelect = (selectedOption) => {};

  const dateSelectorOptions = useMemo(() => {
    switch (activeTab) {
      case "YOUTUBE":
        return {
          options: [
            `6 ${t("analytics.yt.date_selector.months")}`,
            `1 ${t("analytics.yt.date_selector.year")}`,
            `2 ${t("analytics.yt.date_selector.years")}`,
            `5 ${t("analytics.yt.date_selector.years")}`,
          ],
          disabledOptions: [1, 2, 3],
        };
      case "TIKTOK":
        return {
          options: [
            `1 ${t("analytics.tk.date_selector.week")}`,
            `1 ${t("analytics.tk.date_selector.month")}`,
            `2 ${t("analytics.tk.date_selector.months")}`,
          ],
          disabledOptions: [0, 1],
        };
      /* case "INSTAGRAM":
        return {
          options: [
            `7 ${t("analytics.ig.date_selector.days")}`,
            `14 ${t("analytics.ig.date_selector.days")}`,
            `1 ${t("analytics.ig.date_selector.month")}`,
            `3 ${t("analytics.ig.date_selector.months")}`,
          ],
          disabledOptions: [],
        }; */
      default:
        return {
          options: [],
          disabledOptions: [],
        };
    }
  }, [activeTab, t]);

  return (
    <>
      <div className="analyticsNetworksHeader">
        <div className="analyticsNetworksHeader__title">
          <p onClick={handleNavigateProfile}>{creatorData?.username}</p>
          <img src={arrow} alt="arrow" />
          <div>
            <img src={barChart} alt="Bar Chart" />
            <p>{t("analytics.title")}</p>
          </div>
        </div>
        <DateSelectorAnalytics
          options={dateSelectorOptions.options}
          onSelect={handleDateSelect}
          disabledOptions={dateSelectorOptions.disabledOptions}
        />
      </div>
      <div className="analyticsNetworksContainer">
        <div className="analyticsNetworksTabs">
          {networks.map((network) => (
            <div
              key={network.platform}
              className={`analyticsNetworks${
                activeTab === network.platform ? "Active" : ""
              }Tab`}
              onClick={() => handleTabClick(network.platform)}
            >
              <img src={network.icon} alt={network.title} />
              {activeTab === network.platform && (
                <p className="analyticsNetworksActiveTabNet">{network.title}</p>
              )}
            </div>
          ))}
        </div>

        <div className="analyticsNetworksContent">
          {filteredNetworks.map((network, index) => {
            return (
              <div
                key={index}
                className={`${
                  activeAccount === network
                    ? "analyticsNetworksContentInfoAvatar analyticsNetworksContentInfoAvatarActive"
                    : "analyticsNetworksContentInfoAvatar"
                }`}
                onClick={() => handleSelectAccount(network)}
              >
                <img src={network.profile_img_url || avatar} alt="avatar" />
                <p>{network.name}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AnalyticsNetworks;
