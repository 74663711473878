import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "../../constants";

export const analyticsApi = createApi({
  reducerPath: "analyticsQuery",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["analytics"],
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: (networkId) => `/analytics?networkId=${networkId}`,
      providesTags: ["analytics"],
    }),
  }),
});

export const { useGetAnalyticsQuery, middleware } = analyticsApi;
export default analyticsApi.reducer;
