import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import profesional_profile from "../../assets/images/undraw_profesional_profile.png";
import centralized_comunication from "../../assets/images/undraw_centralized_comunication.png";
import centralized_register from "../../assets/images/undraw_register_centralized.png";
import agency_profile from "../../assets/images/undraw_agency_profile.png";
import agency_2 from "../../assets/images/undraw_agency_2.png";
import crm from "../../assets/images/undraw_CRM.png";
import advertiser_1 from "../../assets/images/undraw_advertiser1.png";
import advertiser_2 from "../../assets/images/undraw_advertiser2.png";
import advertiser_3 from "../../assets/images/undraw_advertiser3.png";

import Footer from "../../components/UI/Footer/Footer";

import "./HowItWorksPage.styles.css";

const HowItWorksPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.id) {
      const section = document.getElementById(location.state.id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.state]);
  return (
    <div className="howItWorks">
      <section id="creator" className="section__creators">
        <div className="section__creatorsTitleContainer">
          <div className="section__creatorsTitle">
            <p>{t("how_it_works.creators.title.1")}</p>
            <p>
              <span>{t("how_it_works.creators.title.2")}</span>
              {t("how_it_works.creators.title.3")}
            </p>
          </div>
          <p className="section__creatorsDescription">
            {t("how_it_works.creators.description")}
          </p>
        </div>
        <div className="section__creatorsSection">
          <div className="section__creatorsSectionContainer">
            <div className="text__creatorsSection1">
              <p>{t("how_it_works.creators.section.1.title")}</p>
              <p>{t("how_it_works.creators.section.1.description")}</p>
            </div>
            <div className="img__creatorsSection1">
              <img src={profesional_profile} alt="profesional_profile" />
            </div>
          </div>
          <div className="section__creatorsSectionContainer">
            <div className="img__creatorsSection2">
              <img
                src={centralized_comunication}
                alt="centralized_comunication"
              />
            </div>
            <div className="text__creatorsSection2">
              <p>{t("how_it_works.creators.section.2.title")}</p>
              <p>{t("how_it_works.creators.section.2.description")}</p>
            </div>
          </div>
          <div className="section__creatorsSectionContainer">
            <div className="text__creatorsSection3">
              <p>{t("how_it_works.creators.section.3.title")}</p>
              <p>{t("how_it_works.creators.section.3.description")}</p>
            </div>
            <div className="img__creatorsSection3">
              <img src={centralized_register} alt="centralized_register" />
            </div>
          </div>
        </div>
      </section>
      <section id="agency" className="section__agency">
        <div className="section__agencyTitleContainer">
          <div className="section__agencyTitle">
            <p>{t("how_it_works.agency.title.1")}</p>
            <p>
              <span>{t("how_it_works.agency.title.2")}</span>
              {t("how_it_works.agency.title.3")}
            </p>
          </div>
          <p className="section__agencyDescription">
            {t("how_it_works.agency.description")}
          </p>
        </div>
        <div className="section__agencySection">
          <div className="section__agencySectionContainer">
            <div className="text__agencySection1">
              <p>{t("how_it_works.agency.section.1.title")}</p>
              <p>{t("how_it_works.agency.section.1.description")}</p>
            </div>
            <div className="img__agencySection1">
              <img src={agency_profile} alt="agency_profile" />
            </div>
          </div>
          <div className="section__agencySectionContainer">
            <div className="img__agencySection2">
              <img src={agency_2} alt="agency_2" />
            </div>
            <div className="text__agencySection2">
              <p>{t("how_it_works.agency.section.2.title")}</p>
              <p>{t("how_it_works.agency.section.2.description")}</p>
            </div>
          </div>
          <div className="section__agencySectionContainer">
            <div className="text__agencySection3">
              <p>{t("how_it_works.agency.section.3.title")}</p>
              <p>{t("how_it_works.agency.section.3.description")}</p>
            </div>
            <div className="img__agencySection3">
              <img src={crm} alt="crm" />
            </div>
          </div>
        </div>
      </section>
      <section id="advertiser" className="section__advertiser">
        <div className="section__advertiserTitleContainer">
          <div className="section__advertiserTitle">
            <p>{t("how_it_works.advertiser.title.1")}</p>
            <p>
              <span>{t("how_it_works.advertiser.title.2")}</span>
              {t("how_it_works.advertiser.title.3")}
            </p>
          </div>
          <p className="section__advertiserDescription">
            {t("how_it_works.advertiser.description")}
          </p>
        </div>
        <div className="section__advertiserSection">
          <div className="section__advertiserSectionContainer">
            <div className="text__advertiserSection1">
              <p>{t("how_it_works.advertiser.section.1.title")}</p>
              <p>{t("how_it_works.advertiser.section.1.description")}</p>
            </div>
            <div className="img__advertiserSection1">
              <img src={advertiser_1} alt="advertiser_1" />
            </div>
          </div>
          <div className="section__advertiserSectionContainer">
            <div className="img__advertiserSection2">
              <img src={advertiser_2} alt="advertiser_2" />
            </div>
            <div className="text__advertiserSection2">
              <p>{t("how_it_works.advertiser.section.2.title")}</p>
              <p>{t("how_it_works.advertiser.section.2.description")}</p>
            </div>
          </div>
          <div className="section__advertiserSectionContainer">
            <div className="text__advertiserSection3">
              <p>{t("how_it_works.advertiser.section.3.title")}</p>
              <p>{t("how_it_works.advertiser.section.3.description")}</p>
            </div>
            <div className="img__advertiserSection3">
              <img src={advertiser_3} alt="advertiser_3" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default HowItWorksPage;
