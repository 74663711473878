import React, { useEffect, useRef, useState } from "react";
import { Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import ChartTooltipContent from "../tooltip/ChartTooltipContent";

const PieBasicChart = ({
  data = [], // Datos del gráfico
  dataKey, // Clave para acceder a los valores
  cx, // Posición horizontal del centro
  cy, // Posición vertical del centro
  fill,
  innerRadiusFactor,
  cornerRadius,
  percentage,
}) => {
  const chartContainerRef = useRef(null);
  const [radius, setRadius] = useState(100);

  useEffect(() => {
    const updateRadius = () => {
      if (chartContainerRef.current) {
        const width = chartContainerRef.current.offsetWidth;
        const height = chartContainerRef.current.offsetHeight;
        const minDimension = Math.min(width, height);
        setRadius(minDimension / 2);
      }
    };

    updateRadius();
    window.addEventListener("resize", updateRadius);
    return () => window.removeEventListener("resize", updateRadius);
  }, []);
  return (
    <div ref={chartContainerRef} style={{ width: "100%", height: "100%" }}>
      <ResponsiveContainer>
        <PieChart>
          <Tooltip content={<ChartTooltipContent percentage={percentage} />} />
          <Pie
            dataKey={dataKey}
            data={data}
            cx={cx}
            cy={cy}
            outerRadius={radius}
            innerRadius={radius * innerRadiusFactor}
            fill={fill}
            isAnimationActive={true}
            cornerRadius={cornerRadius}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PieBasicChart;
