export const API_URL = process.env.REACT_APP_API_URL;

export const PLATFORMS = {
  Youtube: "YOUTUBE",
  Instagram: "INSTAGRAM",
  Tiktok: "TIKTOK",
  Twitter: "TWITTER",
  Twitch: "TWITCH",
};

export const TIKTOK_URL =
  "https://www.tiktok.com/v2/auth/authorize?client_key=7376470208887652369&scope=user.info.basic%2Cuser.info.username%2Cuser.info.stats%2Cuser.info.profile%2Cuser.account.type%2Cuser.insights%2Cvideo.list%2Cvideo.insights&response_type=code&redirect_uri=https%3A%2F%2Fapi.influenly.app%2Fnetwork%2Ftiktok";
