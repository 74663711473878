import React from "react";
import eye from "../../../assets/icons/IconEyePurple.svg";
import "./AnalyticsCharts.styles.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ContentYT from "./AnalyticsYT/ContentYT";
import ContentTK from "./AnalyticsTK/ContentTK";

const AnalyticsContent = ({ analyticsContent }) => {
  const { t } = useTranslation();

  const platform = useSelector((state) => state.analytics.platformSelected);

  return (
    <div className="analyticsChartsContainer">
      <div className="analyticsChartsTabs">
        <div className="analyticsChartsActiveTab">
          <img src={eye} alt="eye" />
          <p>{t("analytics.sections.content")}</p>
        </div>
      </div>
      <div className="analyticsChartsContent">
        {platform === "YOUTUBE" && (
          <ContentYT analyticsContent={analyticsContent} />
        )}
        {platform === "TIKTOK" && (
          <ContentTK analyticsContent={analyticsContent} />
        )}
        {/* {platform === "INSTAGRAM" && <ContentIG />}  */}
      </div>
    </div>
  );
};

export default AnalyticsContent;
